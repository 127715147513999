import React from "react";
import { Container, Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

const ProductNavigation = () => {

    return (
        <Container>
            <Nav variant="tabs" className="my-4">

                <Nav.Item variant="outline-secondary">
                    <Nav.Link as={NavLink} to="/products" active={false} eventKey={"1"}>
                        Product
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item variant="outline-secondary">
                    <Nav.Link as={NavLink} to="/products/sku" eventKey={"2"}>
                        SKUs
                    </Nav.Link>
                </Nav.Item>

            </Nav>
            <Outlet />
        </Container>
    );
};

export default ProductNavigation;
