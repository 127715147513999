import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import { errorToast, successToast } from "../../Services/toastService";
import { useSelector } from "react-redux";
import {
  addTrackingComment,
  trackingDownload,
  trackingFilter,
  updateTrackingData,
  updateTrackingDataManually,
} from "../../Services/tracking";
import moment from "moment/moment";
import TooltipComponent from "../../SharedComponents/tooltip/tooltip";
import DatePicker from "react-datepicker";
import FilterSearch from "../../SharedComponents/filterSearch/filterSearch";
import Pagination from "react-bootstrap/Pagination";
import TrackingDialog from "../../SharedComponents/trackingDialog/trackingDialog";
import * as XLSX from 'xlsx';
import InfiniteScroll from 'react-infinite-scroll-component';

const Tracking = () => {
  const [tableData, setTableData] = useState(null);
  const [dataCount, setDataCount] = useState();
  const token = useSelector((state) => state.token);
  const [trackingModal, setTrackingModal] = useState(false);
  const [orderBy, setOrderBy] = useState({ createdAt: "desc" });
  const statusOptions = {
    "PENDING": "pending",
    "INFO RECEIVED": "info_received",
    "IN TRANSIT": "in_transit",
    "OUT FOR DELIVERY": "out_for_delivery",
    "FAILED ATTEMPT": "failed_attempt",
    "AVAILABLE FOR PICKUP": "available_for_pickup",
    "DELIVERED": "delivered",
    // "NOT DELIVERED": "!delivered",
    "EXCEPTION": "exception",
    "OTHER": "other",
    "RETURNED": "returned",
    "NULL": null,
  };

  const optionalObj = {
    "CONFIG_ERROR": "config_error",
    "ERROR": "error",
    "NULL": null,
  }

  const [filterArr, setFilterArr] = useState([])
  const [reqObj, setReqObj] = useState({
    trackingId: "",
    infoReceivedDate: "",
    outFromIndiaDate: "",
    inToSourceDate: "",
    deliveredDate: "",
    otherStatus: "",
    remark: "",
  });
  const invalidStr = ["NYD", "PROCESSING", "HOLD", "CANCELED"];
  const filterSearchRef1 = useRef(null);
  const filterSearchRef2 = useRef(null);
  const filterSearchRef3 = useRef(null);
  const filterSearchRef4 = useRef(null);
  const filterSearchRef5 = useRef(null);
  const filterSearchRef6 = useRef(null);
  const filterSearchRef7 = useRef(null);
  const filterSearchRef8 = useRef(null);
  const filterSearchRef9 = useRef(null);
  const [trackingInfoModal, setTrackingInfoModal] = useState(false);
  const [infoObj, setInfoObj] = useState(null);
  const [comment, setComment] = useState("");
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [limit, setLimit] = useState(10)
  const [withFilterBtnLoading, setWithFilterBtnLoading] = useState(false)
  let items = [];
  let paginationNum = [10, 25, 50, 100, 500];
  paginationNum.forEach((number) => {
    items.push(
      <Pagination.Item
        key={number}
        active={number === limit}
        onClick={() => setLimit(number)}
      >
        {number}
      </Pagination.Item>
    );
  });

  const resetBtn = async () => {
    try {
      const response = await trackingFilter(token, filterArr, orderBy, limit, null);
      const data = response.data.trackingResults;
      const count = response.data.totalCount;
      setTableData(data);
      setDataCount(count);
      setOrderBy({ createdAt: "desc" })
      filterSearchRef1.current && filterSearchRef1.current.resetFilter();
      filterSearchRef2.current && filterSearchRef2.current.resetFilter();
      filterSearchRef3.current && filterSearchRef3.current.resetFilter();
      filterSearchRef4.current && filterSearchRef4.current.resetFilter();
      filterSearchRef5.current && filterSearchRef5.current.resetFilter();
      filterSearchRef6.current && filterSearchRef6.current.resetFilter();
      filterSearchRef7.current && filterSearchRef7.current.resetFilter();
      filterSearchRef8.current && filterSearchRef8.current.resetFilter();
      filterSearchRef9.current && filterSearchRef9.current.resetFilter();
      setFilterArr([])
      setLimit(10)
    } catch (err) {
      console.log(err);
    }
  };

  const search = async () => {
    try {
      const response = await trackingFilter(
        token,
        filterArr,
        orderBy,
        limit,
        null
      );
      const data = response.data.trackingResults;
      const count = response.data.totalCount;
      setTableData(data);
      setDataCount(count);
    } catch (err) {
      errorToast("Something Went Wrong");
    }
  };

  const handleLoadMore = async () => {
    try {
      const response = await trackingFilter(
        token,
        filterArr,
        orderBy,
        limit,
        tableData.length
      );
      let table = [...tableData];
      table.push(...response.data.trackingResults);
      setTableData(table);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateTrackingData = async (id) => {
    try {
      const res = await updateTrackingData(token, id);
      if (res.status === 200) successToast(res.data.message);
      search()
    } catch (err) {
      errorToast(err.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [filterArr, orderBy, limit]);

  const handleCloseTrackingModal = () => {
    setReqObj({
      EDN: "",
      infoReceivedDate: "",
      outFromIndiaDate: "",
      inToSourceDate: "",
      deliveredDate: "",
      remark: "",
    });
    setTrackingModal(false);
  };

  const handleTrackingUpdate = async () => {
    try {
      if (!reqObj.remark) {
        return errorToast("Remark is required!!!");
      }
      if (!reqObj.otherStatus.trim()) return errorToast("Status is required")
      const res = await updateTrackingDataManually(token, reqObj);
      if (res.status === 200) {
        successToast(res.data.message);
        // resetBtn();
        search()
        handleCloseTrackingModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filterSearch = async (obj, isSelected) => {
    let current = filterArr;
    if (obj.key) {
      current = current.filter((item) => !(item.column === obj.column && item.key === obj.key));
    } else {
      current = current.filter((item) => item.column !== obj.column);
    }
    if (isSelected) {
      setFilterArr(current)
    } else {
      current.push(obj);
      setFilterArr(current);
    }
  };

  const handleAddTrackingRemark = async (e) => {
    try {
      setIsCommentLoading(true)
      e.preventDefault()
      if (!comment.trim()) {
        return errorToast("Field is empty")
      }
      const res = await addTrackingComment(token, infoObj.id, comment.trim())
      if (res.status === 200) {
        console.log(res.data.data)
        successToast(res.data.message)
        const newEvent = {
          remark: comment,
          user: {
            email: user.email,
            id: user.id,
            role: user.role
          },
          createdAt: new Date()
        }
        let temp = JSON.parse(JSON.stringify(infoObj))
        temp.trackingMetadata = temp.trackingMetadata || {}
        temp.trackingMetadata.eventHistory = temp?.trackingMetadata?.eventHistory || []
        temp?.trackingMetadata?.eventHistory.push(newEvent)
        setInfoObj(temp)
        setComment("");
      }
    } catch (error) {
      errorToast(error.response?.data?.message || "Something Went Wrong")
    } finally {
      setIsCommentLoading(false)
    }
  }

  const handleDownloadView = () => {
    const formatDate = (timestamp) => {
      if (!timestamp) return '-';
      return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
    };
    const headers = [
      "id",
      "trackingId",
      "country",
      "currentStatus",
      "deliveredDatetime",
      "inToSourceDateTime",
      "infoReceivedDatetime",
      "outFromIndiaDateTime",
      "remark",
      "createdAt"
    ];
    const wsData = [headers, ...tableData.map(row => [
      row.id,
      row.trackingId,
      row.country,
      row.trackingMetadata?.currentStatus ?? '-',
      formatDate(row.trackingMetadata?.deliveredDatetime),
      formatDate(row.trackingMetadata?.inToSourceDateTime),
      formatDate(row.trackingMetadata?.infoReceivedDatetime),
      formatDate(row.trackingMetadata?.outFromIndiaDateTime),
      row.trackingMetadata?.remark ?? '-',
      row.createdAt
    ])];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'tracking_data.xlsx');
  };


  const handleDownloadResult = async () => {
    try {
      setWithFilterBtnLoading(true)
      const res = await trackingDownload(token, filterArr, orderBy);
      const data = res.data.trackingResults;

      // Define the headers for the Excel sheet
      const headers = ["EDN", "Tracking ID", "Country", "Status", "InfoReceived", "OutFromIndia", "IntoSource", "Delivered", "Placed At", "Remark"];

      // Map the data to include only the required fields
      const formattedData = data.map(item => ({
        EDN: item.id,
        "Tracking ID": item.trackingId,
        Country: item.country,
        Status: item.trackingMetadata?.currentStatus || "-",
        InfoReceived: typeof item.trackingMetadata?.infoReceivedDatetime === 'string' ?
          item.trackingMetadata?.infoReceivedDatetime :
          item.trackingMetadata?.infoReceivedDatetime ?
            moment(item.trackingMetadata?.infoReceivedDatetime).format("YYYY-MM-DD") :
            "N/A",
        OutFromIndia: typeof item.trackingMetadata?.outFromIndiaDateTime === 'string' ?
          item.trackingMetadata?.outFromIndiaDateTime :
          item.trackingMetadata?.outFromIndiaDateTime ?
            moment(item.trackingMetadata?.outFromIndiaDateTime).format("YYYY-MM-DD") :
            "N/A",
        IntoSource: typeof item.trackingMetadata?.inToSourceDateTime === 'string' ?
          item.trackingMetadata?.inToSourceDateTime :
          item.trackingMetadata?.inToSourceDateTime ?
            moment(item.trackingMetadata?.inToSourceDateTime).format("YYYY-MM-DD") :
            "N/A",
        Delivered: typeof item.trackingMetadata?.deliveredDatetime === 'string' ?
          item.trackingMetadata?.deliveredDatetime :
          item.trackingMetadata?.deliveredDatetime ?
            moment(item.trackingMetadata?.deliveredDatetime).format("YYYY-MM-DD") :
            "N/A",
        "Placed At": moment(item.createdAt).format("YYYY-MM-DD HH:mm"),
        Remark: item.trackingMetadata?.remark || "-"
      }));

      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Add the data as a new sheet to the workbook
      const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers });
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Save the workbook as an Excel file
      XLSX.writeFile(workbook, "TrackingResults.xlsx");
    } catch (error) {
      console.log(error);
      errorToast(error?.response?.data.message || "Something went wrong")
    } finally {
      setWithFilterBtnLoading(false)
    }
  };

  const handleUpdateSelect = (item) => {
    setReqObj({
      deliveredDate: typeof item.trackingMetadata?.deliveredDatetime !== 'string' ? item.trackingMetadata?.deliveredDatetime : null,
      infoReceivedDate: typeof item.trackingMetadata?.infoReceivedDatetime !== 'string' ? item.trackingMetadata?.infoReceivedDatetime : null,
      inToSourceDate: typeof item.trackingMetadata?.inToSourceDateTime !== 'string' ? item.trackingMetadata?.inToSourceDateTime : null,
      outFromIndiaDate: typeof item.trackingMetadata?.outFromIndiaDateTime !== 'string' ? item.trackingMetadata?.outFromIndiaDateTime : null,
      status: item?.trackingMetadata?.otherStatus,
      EDN: item?.id
    })
    setTrackingModal(true);
  }

  return (
    <Container>
      <h4 className="my-4">Tracking</h4>

      <div className="mb-2 d-flex justify-content-between align-items-center">
        <div>
          <Button variant="outline-secondary" onClick={handleDownloadView}>
            <i className="fa-solid fa-download"></i> View
          </Button>
          <Button variant="outline-secondary" onClick={handleDownloadResult} className="ms-2" disabled={withFilterBtnLoading}>
            <i className="fa-solid fa-download"></i> With Filter
          </Button>
          <Button
            variant="outline-danger"
            id="button-addon2"
            onClick={() => resetBtn()}
            className="ms-2"
          >
            <i className="fa-solid fa-rotate-left"></i>
          </Button>
        </div>
        {tableData && (<div>{tableData.length} / {dataCount}</div>)}
        <div className="mt-2">
          <Pagination>{items}</Pagination>
        </div>
      </div>

      <InfiniteScroll
        next={handleLoadMore}
        dataLength={tableData?.length || 0}
        hasMore={tableData?.length !== dataCount}
        loader={
          <div className="text-center mt-4">
            <p>Loading...</p>
          </div>
        }
        endMessage={
          <p style={{ textAlign: 'center', marginTop: "20px" }}>
            <b>{tableData && (<div>{tableData.length} / {dataCount}</div>)}</b>
          </p>
        }
      >
        <Table bordered responsive className="mt-2">
          <thead>
            <tr>
              <FilterSearch
                name="EDN"
                table="orderItem"
                column="id"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef1}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <FilterSearch
                name="Tracking ID"
                table="orderItem"
                column="trackingId"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef2}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <FilterSearch
                name="Country"
                table="orderItem"
                column="country"
                type="equal"
                search={filterSearch}
                ref={filterSearchRef3}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <FilterSearch
                name="Status"
                table="orderItem"
                column="trackingMetadata"
                type="JSON_equals"
                search={filterSearch}
                ref={filterSearchRef4}
                optionsObj={statusOptions}
                keyValue="currentStatus"
              />
              <FilterSearch
                name="InfoReceived"
                table="orderItem"
                column="trackingMetadata"
                type="JSON_date"
                ref={filterSearchRef5}
                search={filterSearch}
                keyValue="infoReceivedDatetime"
                optionsObj={optionalObj}
              />
              <FilterSearch
                name="OutFromIndia"
                table="orderItem"
                column="trackingMetadata"
                type="JSON_date"
                search={filterSearch}
                ref={filterSearchRef6}
                keyValue="outFromIndiaDateTime"
                optionsObj={optionalObj}
              />
              <FilterSearch
                name="IntoSource"
                table="orderItem"
                column="trackingMetadata"
                type="JSON_date"
                search={filterSearch}
                ref={filterSearchRef7}
                keyValue="inToSourceDateTime"
                optionsObj={optionalObj}
              />
              <FilterSearch
                name="Delivered"
                table="orderItem"
                column="trackingMetadata"
                type="JSON_date"
                search={filterSearch}
                ref={filterSearchRef8}
                keyValue="deliveredDatetime"
                optionsObj={optionalObj}
              />
              <FilterSearch
                name={"Placed At"}
                table="orderItem"
                column="createdAt"
                type="date"
                search={filterSearch}
                ref={filterSearchRef9}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <th>Remark</th>
              <th>Action</th>
            </tr>
          </thead>
          {tableData && !!tableData.length && (
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index} className={`${item.trackingMetadata?.currentStatus === "exception" ? "border-2 border-danger" : ""}`}>
                  <td>
                    {item.id}
                    <i
                      className="fa-solid fa-circle-info ms-2 download_Link"
                      onClick={(e) => {
                        setTrackingInfoModal(true);
                        setInfoObj(item);
                      }}
                    ></i>
                  </td>
                  <td>
                    <TrackingDialog item={item} />
                  </td>
                  <td>{item.country}</td>
                  <td>{item.trackingMetadata?.currentStatus}</td>
                  <td>
                    {
                      typeof item.trackingMetadata?.infoReceivedDatetime === 'string' ?
                        item.trackingMetadata?.infoReceivedDatetime :
                        item.trackingMetadata?.infoReceivedDatetime ?
                          moment(item.trackingMetadata?.infoReceivedDatetime).format("YYYY-MM-DD") :
                          "N/A"}
                  </td>
                  <td>
                    {
                      typeof item.trackingMetadata?.outFromIndiaDateTime === 'string' ?
                        item.trackingMetadata?.outFromIndiaDateTime :
                        item.trackingMetadata?.outFromIndiaDateTime ?
                          moment(item.trackingMetadata?.outFromIndiaDateTime).format("YYYY-MM-DD") :
                          "N/A"
                    }
                  </td>
                  <td>
                    {
                      typeof item.trackingMetadata?.inToSourceDateTime === 'string' ?
                        item.trackingMetadata?.inToSourceDateTime :
                        item.trackingMetadata?.inToSourceDateTime ?
                          moment(item.trackingMetadata?.inToSourceDateTime).format("YYYY-MM-DD") :
                          "N/A"
                    }
                  </td>
                  <td>
                    {
                      typeof item.trackingMetadata?.deliveredDatetime === 'string' ?
                        item.trackingMetadata?.deliveredDatetime :
                        item.trackingMetadata?.deliveredDatetime ?
                          moment(item.trackingMetadata?.deliveredDatetime).format("YYYY-MM-DD") :
                          "N/A"
                    }
                  </td>
                  <td>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                  <td>
                    {item.trackingMetadata?.remark
                      ? item.trackingMetadata?.remark
                      : "-"}
                  </td>
                  <td key={item.id}>
                    {!invalidStr.includes(
                      String(item.trackingId).trim().toUpperCase()
                    ) && (
                        <>
                          <TooltipComponent
                            children={
                              <Button
                                size="sm"
                                variant="primary"
                                className="me-2"
                                disabled={
                                  item.trackingMetadata?.currentStatus === 'other'
                                  //   !item.trackingMetadata?.infoReceivedDatetime |
                                  //   !item.trackingMetadata?.outFromIndiaDateTime |
                                  //   !item.trackingMetadata?.inToSourceDateTime |
                                  //   !item.trackingMetadata?.deliveredDatetime
                                  //     ? false
                                  //     : true
                                }
                                onClick={() =>
                                  handleUpdateTrackingData(item.trackingId)
                                }
                              >
                                <i className="fa-solid fa-rotate-right"></i>
                              </Button>
                            }
                            content={"Update"}
                          />
                          <TooltipComponent
                            children={
                              <Button
                                size="sm"
                                variant="primary"
                                // disabled={
                                //   item.trackingMetadata?.currentStatus === 'other'
                                //   !item.trackingMetadata?.infoReceivedDatetime |
                                //   !item.trackingMetadata?.outFromIndiaDateTime |
                                //   !item.trackingMetadata?.inToSourceDateTime |
                                //   !item.trackingMetadata?.deliveredDatetime
                                //     ? false
                                //     : true
                                // }
                                onClick={() => {
                                  handleUpdateSelect(item)
                                }}
                              >
                                <i className="fa-regular fa-pen-to-square"></i>
                              </Button>
                            }
                            content={"Tracking Date update"}
                          />
                        </>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </InfiniteScroll>
      {tableData && !tableData.length && (
        <div className="mt-4 text-center">NO DATA AVAILABLE</div>
      )}

      {/* {tableData && !!tableData.length && (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {tableData.length} / {dataCount}
          </div>
          <div className="mt-2">
            <Pagination>{items}</Pagination>
          </div>
        </div>
      )} */}
      {/* <div className="text-center my-2">
        {tableData && dataCount > tableData.length ? (
          <Button variant="dark" onClick={handleLoadMore}>
            Load More
          </Button>
        ) : null}
      </div> */}
      <Modal show={trackingModal} onHide={handleCloseTrackingModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Tracking Details {reqObj.EDN}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Info Received Date</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="date-picker-style w-100"
                selected={reqObj.infoReceivedDate}
                onChange={(date) => {
                  setReqObj({ ...reqObj, infoReceivedDate: date });
                }}
                disabledKeyboardNavigation
                placeholderText=" Select Date"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Out From India Date</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="date-picker-style w-100"
                selected={reqObj.outFromIndiaDate}
                onChange={(date) => {
                  setReqObj({ ...reqObj, outFromIndiaDate: date });
                }}
                disabledKeyboardNavigation
                placeholderText=" Select Date"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Into Destination Date</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="date-picker-style w-100"
                selected={reqObj.inToSourceDate}
                onChange={(date) => {
                  setReqObj({ ...reqObj, inToSourceDate: date });
                }}
                disabledKeyboardNavigation
                placeholderText=" Select Date"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Delivered Date</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="date-picker-style w-100"
                selected={reqObj.deliveredDate}
                onChange={(date) => {
                  setReqObj({ ...reqObj, deliveredDate: date });
                }}
                disabledKeyboardNavigation
                placeholderText=" Select Date"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Select
                required
                value={reqObj.status}
                onChange={(e) =>
                  setReqObj({ ...reqObj, otherStatus: e.target.value })
                }
              >
                <option value="">Select Status</option>
                <option value="delivered">Delivered</option>
                <option value="returned">Returned</option>
                <option value="other">Other</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                type="text"
                placeholder=" remark"
                value={reqObj.remark}
                onChange={(e) =>
                  setReqObj({ ...reqObj, remark: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTrackingModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleTrackingUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal for tracking Info */}

      <Modal
        show={trackingInfoModal}
        onHide={() => {
          setComment("");
          setInfoObj(null);
          setTrackingInfoModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tracking History Info for {infoObj?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            !!infoObj?.trackingMetadata?.eventHistory?.length ? (
              <Table bordered>
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>Email</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {infoObj?.trackingMetadata?.eventHistory.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.remark}</td>
                      <td>{item?.user?.email}</td>
                      <td>
                        {item?.createdAt
                          ? moment(item?.createdAt).format("DD/MM/YY HH:mm")
                          : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="text-center">
                No Data Available
              </div>
            )
          }
          <hr />
          <Form autoComplete="off" onSubmit={handleAddTrackingRemark}>
            <Form.Group className="mb-3">
              <Form.Label>Add Comment/Remark</Form.Label>
              <Form.Control
                type="text"
                required
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="warning"
              type="submit"
              disabled={isCommentLoading}
            >
              Add
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setComment("")
              setInfoObj(null);
              setTrackingInfoModal(false);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Tracking;
