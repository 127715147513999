import React, { useEffect, useState } from "react";
import  Button from "react-bootstrap/Button";
import  Container from "react-bootstrap/Container";
import  Form from "react-bootstrap/Form";
import  Modal from "react-bootstrap/Modal";
import  Table  from "react-bootstrap/Table";
import  Badge  from "react-bootstrap/Badge";
import  Spinner  from "react-bootstrap/Spinner";
import {
  getConfigByKey,
  getEmail,
  mailDispatcher,
  updateConfigByKey,
  updateMetaData,
} from "../../../Services/adminService";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { errorToast, successToast } from "../../../Services/toastService";

const Email = () => {
  const [emailData, setEmailData] = useState([]);
  const [trackingDetailsLoading, setTrackingDetailsLoading] = useState(false);
  const [cc, setCc] = useState([]);
  const [addCC, setAddCC] = useState("");
  const [to, setTo] = useState([]);
  const [addTo, setAddTo] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [fromEmail, setFromEmail] = useState("");
  const [bcc, setBcc] = useState([]);
  const [addBcc, setAddBcc] = useState("");
  const [name, setName] = useState("");
  const [toggle, setToggle] = useState(true);
  const token = useSelector((state) => state.token);
  const [internalOrderStatus, setInternalOrderStatus] = useState({});
  const [selectedFilter, setSelectedFilter] = useState({});
  const [filterUpdateDialog, setFilterUpdateDialog] = useState(false);

  const nameKeyMapping = {
    dispatchTrackingID: "Send Tracking Details",
    dispatchOrder: "Order Placed (Dispatch)",
    placeOrder: "Order Acknowledgement (Customer)",
    support: "Support Email",
    originalFileOfOrder: "Original File Of Order",
    creditLimitWarning: "Wallet Alert (Client)",
    dailyAccounting: "Daily Accounting",
    clientOrderSummary: "Client Order Summary",
  };

  const iconStyle = {
    cursor: "pointer",
    fontSize: "13px",
    marginLeft: "5px",
  };

  const badgeStyle = {
    marginRight: "5px",
    marginTop: "3px",
    fontSize: "13px",
  };

  const getData = async () => {
    try {
      const response = await getEmail(token);
      const res = response.data.data;
      setEmailData(res);
    } catch (err) {
      errorToast("Something went Wrong");
    }
  };

  const modalBtn = () => {
    if (modalShow) {
      setFromEmail("");
      setAddTo("");
      setAddCC("");
      setAddBcc("");
    }
    setModalShow(!modalShow);
  };

  const updateBtn = (item) => {
    setFromEmail(item.metaData.from);
    setCc(item.metaData.cc);
    setTo(item.metaData.to);
    setBcc(item.metaData.bcc);
    setName(item.name);
    modalBtn();
  };

  const handleAddEmail = (email, arrayType) => {
    if (arrayType === "cc") {
      setCc((prevCc) => {
        if (prevCc.includes(email)) {
          errorToast("Email already exists in CC");
          return prevCc;
        } else {
          return [...prevCc, email];
        }
      });
      setAddCC("");
    } else if (arrayType === "to") {
      setTo((prevTo) => {
        if (prevTo.includes(email)) {
          errorToast("Email already exists in TO");
          return prevTo;
        } else {
          return [...prevTo, email];
        }
      });
      setAddTo("");
    } else if (arrayType === "bcc") {
      setBcc((prevBcc) => {
        if (prevBcc.includes(email)) {
          errorToast("Email already exists in BCC");
          return prevBcc;
        } else {
          return [...prevBcc, email];
        }
      });
      setAddBcc("");
    }
  };

  const handleKeyPress = (e, arrayType) => {
    if (e.keyCode === 13) {
      if (arrayType === "cc") {
        if (isValidEmail(addCC)) {
          handleAddEmail(addCC, "cc");
        } else {
          errorToast("Invalid CC email");
        }
      } else if (arrayType === "to") {
        if (isValidEmail(addTo)) {
          handleAddEmail(addTo, "to");
        } else {
          errorToast("Invalid TO email");
        }
      } else if (arrayType === "bcc") {
        if (isValidEmail(addBcc)) {
          handleAddEmail(addBcc, "bcc");
        } else {
          errorToast("Invalid BCC email");
        }
      }
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRemoveEmail = (email, arrayType) => {
    if (arrayType === "cc") {
      setCc((prevCc) => prevCc.filter((item) => item !== email));
    } else if (arrayType === "to") {
      setTo((prevTo) => prevTo.filter((item) => item !== email));
    } else if (arrayType === "bcc") {
      setBcc((prevTo) => prevTo.filter((item) => item !== email));
    }
  };

  const updateData = async () => {
    try {
      const updatedMetadata = {
        name: name,
        metaData: {
          from: fromEmail,
          cc: cc,
          bcc: bcc,
          to: to,
        },
      };

      if (!fromEmail.includes("@cyno.co.in")) {
        return errorToast("from mail should be @cyno.co.in type");
      }

      const response = await updateMetaData(token, updatedMetadata);
      if (response.status === 200) {
        successToast("Updated Successfully");
        modalBtn();
        getData();
      }
    } catch (err) {
      errorToast("Something went wrong");
    }
  };

  const getConfig = async () => {
    try {
      const res = await getConfigByKey(token, "sendRawFile");
      if (res.status === 200) {
        const keyValue = res.data.value;
        if (keyValue === "yes") {
          setToggle(true);
        } else if (keyValue === "no") {
          setToggle(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getFilterMetaDataKey = async () => {
    try {
      const res = await getConfigByKey(token, "internalOrderStatus");
      if (res.status === 200) {
        const resObj = res.data;

        setInternalOrderStatus(resObj);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateSendFileConfig = async (e) => {
    try {
      const res = await updateConfigByKey(
        token,
        "sendRawFile",
        e.target.checked ? "yes" : "no"
      );
      successToast(res.data.message);
    } catch (err) {
      setToggle(!e.target.checked);
      errorToast("Something went wrong");
    }
  };

  const cronManualTrigger = async () => {
    try {
      setTrackingDetailsLoading(true);
      const res = await mailDispatcher(token);
      successToast(res.data.message);
    } catch (err) {
      // setToggle(!e.target.checked);
      console.log(err);
      errorToast("Something went wrong");
    } finally {
      setTrackingDetailsLoading(false);
    }
  };

  const addToFilterArray = (e) => {
    if (e.keyCode === 13) {
      let temp = selectedFilter;
      if (e.target.value) temp.metaData.push(e.target.value);
      e.target.value = ""
      setSelectedFilter({ ...temp });
    }
  };

  const handleRemoveStatus = (index) => {
    let temp = selectedFilter;
    temp.metaData.splice(index, 1);
    setSelectedFilter({ ...temp });
  };

  const updateFilterMetaData = async () => {
    try {
      await updateConfigByKey(token, selectedFilter.key, "", selectedFilter.metaData);
      successToast(`${selectedFilter.key} status updated successfully`)
      setFilterUpdateDialog(false)
      await getFilterMetaDataKey()
    } catch (err) {
      errorToast("Something went Wrong");
    }
  };

  useEffect(() => {
    getData();
    getConfig();
    getFilterMetaDataKey();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container>
        <h4 className="my-4">Email Metadata</h4>
        <Form.Check
          className="mb-2"
          type="switch"
          id="custom-switch"
          label="Send original file with order"
          checked={toggle}
          onChange={() => setToggle(!toggle)}
          onClick={updateSendFileConfig}
        />
        {emailData && (
          <Table stripped="true" bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Updated At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {emailData.map((item, index) => (
                <tr key={index}>
                  <td>{nameKeyMapping[item.name] || item.name}</td>
                  <td>
                    {moment(item.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td key={item.name} className="w-25">
                    <Button
                      className="me-2"
                      size="sm"
                      variant="primary"
                      onClick={() => updateBtn(item)}
                    >
                      Update
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <h4 className="my-4">Filter Meta Data</h4>
        <Table stripped="true" bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Filter</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{internalOrderStatus.key}</td>
              <td>{internalOrderStatus?.metaData?.join(", ")}</td>
              <td className="w-25">
                <Button
                  className="me-2"
                  size="sm"
                  variant="primary"
                  onClick={() => {
                    setSelectedFilter(JSON.parse(JSON.stringify(internalOrderStatus)));
                    setFilterUpdateDialog(true);
                  }}
                >
                  Update
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>

        <h4 className="my-4">Trigger Events</h4>
        <Table stripped="true" bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Send tracking email</td>
              <td>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={cronManualTrigger}
                  disabled={trackingDetailsLoading}
                >
                  {trackingDetailsLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Trigger Event"
                  )}
                  {/* Trigger Event */}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
      <Modal show={modalShow} onHide={modalBtn} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Update Email for {nameKeyMapping[name]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label>From</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              value={fromEmail}
              onChange={(e) => setFromEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>CC</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter CC"
              value={addCC}
              onChange={(e) => setAddCC(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e, "cc")}
            />
            {cc.map((email, index) => (
              <Badge key={index} className="mr-2" style={badgeStyle}>
                {email}
                <span
                  className="ml-2"
                  onClick={() => handleRemoveEmail(email, "cc")}
                  style={iconStyle}
                >
                  <i className="fa-solid fa-x"></i>
                </span>
              </Badge>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>TO</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter TO"
              value={addTo}
              onChange={(e) => setAddTo(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e, "to")}
            />
            {to.map((email, index) => (
              <Badge key={index} className="mr-2" style={badgeStyle}>
                {email}
                <span
                  className="ml-2"
                  onClick={() => handleRemoveEmail(email, "to")}
                  style={iconStyle}
                >
                  <i className="fa-solid fa-x"></i>
                </span>
              </Badge>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>BCC</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter BCC"
              value={addBcc}
              onChange={(e) => setAddBcc(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e, "bcc")}
            />
            <div>
              {bcc.map((email, index) => (
                <Badge key={index} className="mr-2" style={badgeStyle}>
                  {email}
                  <span
                    className="mr-2"
                    onClick={() => handleRemoveEmail(email, "bcc")}
                    style={iconStyle}
                  >
                    <i className="fa-solid fa-x"></i>
                  </span>
                </Badge>
              ))}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={modalBtn}>
            Close
          </Button>
          <Button variant="primary" onClick={updateData}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={filterUpdateDialog}
        onHide={() => setFilterUpdateDialog(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Email for {selectedFilter.key}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter status"
              onKeyDown={addToFilterArray}
            />
            {selectedFilter.metaData &&
              selectedFilter.metaData.map((status, index) => (
                <Badge key={index} className="mr-2" style={badgeStyle}>
                  {status}
                  <span
                    className="ml-2"
                    onClick={() => handleRemoveStatus(index)}
                    style={iconStyle}
                  >
                    <i className="fa-solid fa-x"></i>
                  </span>
                </Badge>
              ))}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setFilterUpdateDialog(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={updateFilterMetaData}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Email;
