import React, { useState, useEffect, useRef } from "react";
import {
  cancelOrder,
  cancelOrderItem,
  deleteOrder,
  fetchOrder,
  orderSearch,
  reprocessDispatch,
  reprocessOrder,
  updateFilename,
  updateItemName,
  updateOrder,
  updatePrice,
} from "../../Services/orderService";
import "./orderHistory.css";
import { useSelector } from "react-redux";
import { TruncateCell } from "../../SharedComponents/truncateCell/truncateCell";
import moment from "moment";
import { getConfigByKey } from "../../Services/adminService";
import {
  errorToast,
  infoToast,
  successToast,
} from "../../Services/toastService";
import { masterImportTrigger, sendDispatchMail } from "../../Services/dispatch";
import FilterSearch from "../../SharedComponents/filterSearch/filterSearch";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "react-bootstrap/Pagination";
import * as XLSX from "xlsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import HistoryComment from "../../SharedComponents/historyComment/historyComment";
import TooltipComponent from "../../SharedComponents/tooltip/tooltip";
import TrackingDialog from "../../SharedComponents/trackingDialog/trackingDialog";
import { useLocale } from "../../lib/customHooks/useLocale";
import InfiniteScroll from "react-infinite-scroll-component";

const OrderHistory = () => {
  const { t } = useLocale("common")
  const [cancelItemId, setCancelItemid] = useState({
    id: "",
    notifyCustomer: true
  });
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [reprocessLoading, setReprocessLoading] = useState(false);
  const [reprocessDispatchLoading, setReprocessDispatchLoading] =
    useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const token = useSelector((state) => state.token);
  const role = useSelector((state) => state.user.role);
  const [selectedOrderList, setSelectedOrderList] = useState(null);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(undefined);
  const [internalOrderStatus, setInternalOrderStatus] = useState("");
  const [internalOrderList, setInternalOrderList] = useState([]);
  const [internalStatusLoading, setInternalStatusLoading] = useState(false);
  const [searchObj, setSearchObj] = useState([]);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [sendMasterEmailLoading, setSendMasterEmailLoading] = useState(false);
  const filterSearchRef1 = useRef(null);
  const filterSearchRef2 = useRef(null);
  const filterSearchRef3 = useRef(null);
  const filterSearchRef4 = useRef(null);
  const filterSearchRef5 = useRef(null);
  const filterSearchRef6 = useRef(null);
  const [limit, setLimit] = useState(25);
  const [updateProduct, setUpdateProduct] = useState({
    id: "",
    productName: "",
    shipmentType: "",
    country: "",
  });
  const [editBtnIndex, setEditBtnIndex] = useState(-1);
  const [editBtnLoading, setEditBtnLoading] = useState(false);
  const [orderBy, setOrderBy] = useState({
    createdAt: "desc",
  });
  const [toggle, setToggle] = useState(false);
  const [userCurrency, setUserCurrency] = useState("");
  const [updatePriceModal, setUpdatePriceModal] = useState(false);
  const [updatePriceObj, setUpdatePriceObj] = useState({
    id: undefined,
    price: undefined,
    priceInINR: undefined,
  });
  const [updateFilenameModal, setUpdateFilenameModal] = useState(false);
  const [updateFilenameObj, setUpdateFilenameObj] = useState({
    orderId: "",
    newName: "",
  });
  const [filenameLoading, setFilenameLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [allExpanded, setAllExpanded] = useState(false)
  const [infoObj, setInfoObj] = useState(null)
  const [orderItemHistoryInfo, setOrderItemHistoryInfo] = useState(false)

  function handleInputChange(event, index, field) {
    const updatedTableData = [...selectedOrderList];
    updatedTableData[index][field] = event.target.value;
    setSelectedOrderList(updatedTableData);
  }
  const navigate = useNavigate();
  let { id } = useParams();

  let items = [];
  let paginationNum = [25, 50, 100];
  paginationNum.forEach((number, index) => {
    items.push(
      <Pagination.Item
        key={number}
        active={number === limit}
        onClick={() => setLimit(number)}
      >
        {number}
      </Pagination.Item>
    );
  });

  const showOrderHistory = () => {
    setOrderHistoryModal(!orderHistoryModal);
    setEditBtnIndex(-1);
  };
  const handleDeleteModal = () => setConfirmDeleteModal(!confirmDeleteModal);

  let dispatchStatusObj = {
    hold: "Processing",
    pending: "Processing",
    uploaded: "Partial dispatched",
    "partial dispatched": "Partial shipped",
    completed: "Shipped",
  };

  useEffect(() => {
    fetchConfig();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [searchObj, limit, orderBy]);

  const fetchConfig = async () => {
    try {
      const internalOrderList = await getConfigByKey(
        token,
        "internalOrderStatus"
      );
      setInternalOrderList(internalOrderList.data.metaData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      if (id) {
        let newObj = [
          {
            column: "id",
            type: "contains",
            value: id,
          },
        ];
        const response = await orderSearch(token, null, newObj, limit, orderBy);
        const { orders, totalCount } = response.data;
        setOrders(orders);
        setTotalCount(totalCount);
        setLoading(false);
        if (orders[0]) fetchOrderById(orders[0]);
      } else {
        const response = await orderSearch(
          token,
          null,
          searchObj,
          limit,
          orderBy
        );
        const { orders, totalCount } = response.data;
        setOrders(orders);
        setTotalCount(totalCount);
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch orders.", error);
      setLoading(false);
    }
  };

  const filterSearch = async (obj, isSelected) => {
    let current = searchObj;
    current = current.filter((item) => item.column !== obj.column);
    if (isSelected) {
      setSearchObj(current);
    } else {
      current.push(obj);
      setSearchObj(current);
    }
  };

  const resetFilter = () => {
    if (id) {
      navigate("/orderHistory");
    }
    setSearchObj([]);
    filterSearchRef1.current && filterSearchRef1.current.resetFilter();
    filterSearchRef2.current && filterSearchRef2.current.resetFilter();
    filterSearchRef3.current && filterSearchRef3.current.resetFilter();
    filterSearchRef4.current && filterSearchRef4.current.resetFilter();
    filterSearchRef5.current && filterSearchRef5.current.resetFilter();
    filterSearchRef6.current && filterSearchRef6.current.resetFilter();
    // setDispatchStatus("Select Status");
  };

  const handleLoadMore = async () => {
    try {
      const response = await orderSearch(
        token,
        orders.length,
        searchObj,
        limit,
        orderBy
      );
      const additionalOrders = response.data.orders;
      const totalCount = response.data.totalCount;
      const newTable = [...orders, ...additionalOrders];
      setOrders(newTable);
      setTotalCount(totalCount);
    } catch (error) {
      console.error("Failed to fetch orders.", error);
    }
  };

  const handleUpdate = async () => {
    setInternalStatusLoading(true);
    try {
      await updateOrder(token, { id: selectedOrder.id, internalOrderStatus });
      const updatedOrders = orders.map((order) => {
        if (order.id === selectedOrder.id) {
          return { ...order, internalOrderStatus };
        }
        return order;
      });
      setOrders(updatedOrders);
      showOrderHistory();
      successToast("Status Updated Successfully");
      setInternalStatusLoading(false);
    } catch (error) {
      errorToast("Something went wrong");
      console.error(error);
      setInternalStatusLoading(false);
    }
  };

  const reprocessOrderHandle = async () => {
    try {
      setReprocessLoading(true);
      const res = await reprocessOrder(token, selectedOrder.id);
      successToast(res.data.message);
      showOrderHistory();
      setReprocessLoading(false);
      fetchData();
    } catch (err) {
      errorToast(err.response?.data?.message || "Something Went Wrong");
      if (err.response?.data?.orderList) {
        setSelectedOrderList(err.response?.data?.orderList);
      }
      setReprocessLoading(false);
    }
  };

  const reprocessDispatchHandle = async () => {
    try {
      setReprocessDispatchLoading(true);
      const res = await reprocessDispatch(token, selectedOrder.id);
      successToast(res.data.message);
      showOrderHistory();
      fetchData();
    } catch (err) {
      infoToast(err.response?.data?.message || "Something Went Wrong");
    } finally {
      setReprocessDispatchLoading(false);
    }
  };

  const handleSendMail = async () => {
    setSendEmailLoading(true);
    try {
      await sendDispatchMail(token, { orderId: selectedOrder.id });
      showOrderHistory();
      successToast("Email sent Successfully");
      setSendEmailLoading(false);
    } catch (error) {
      errorToast("Something went wrong");
      console.error(error);
      setSendEmailLoading(false);
    }
  };

  const handleMasterImportTrigger = async () => {
    setSendMasterEmailLoading(true);
    try {
      await masterImportTrigger(token, { id: selectedOrder.id });
      successToast("Master import mail successfully sent");
      setSendMasterEmailLoading(false);
    } catch (error) {
      errorToast(error.response?.data?.message || "Something Went Wrong");
    } finally {
      setSendMasterEmailLoading(false);
    }
  };

  const fetchOrderById = async (order) => {
    setModalLoading(true);
    try {
      showOrderHistory();
      const response = await fetchOrder(token, order.id);
      const orderList = response.data.orderList;
      setSelectedOrder(order);
      setSelectedOrderList(orderList);
      setInternalOrderStatus(order.internalOrderStatus || "");
    } catch (error) {
      console.error("Failed to fetch order by ID", error);
    } finally {
      setModalLoading(false);
    }
  };

  const cancelBtnHandle = async () => {
    try {
      setCancelLoading(true);
      const res = await cancelOrderItem(token, cancelItemId);
      if (res.status === 200) {
        successToast(res.data.message);
        const updatedData = selectedOrderList.map((data) => {
          if (data.id === cancelItemId.id) {
            return {
              ...data,
              trackingId: "canceled",
              remark: "canceled",
              price: "0",
              priceInINR: "0",
            };
          } else {
            return data;
          }
        });
        setSelectedOrderList(updatedData);
        handleDeleteModal();
        setCancelItemid({
          id: "",
          notifyCustomer: true
        })
      }
      setCancelLoading(false);
    } catch (err) {
      setCancelLoading(false);
      errorToast(err.response?.data?.message || "Something Went Wrong");
    }
  };

  const handleDownloadList = () => {
    try {
      const data = [
        [
          "File Name",
          "Client",
          "Email",
          "Placed At",
          "Price",
          "Dispatch Status",
          "Account Status",
          "Internal Order Status",
        ],
        ...orders.map((order) => [
          order.fileName,
          order.client?.name || "",
          order.client?.email || "",
          moment(order.createdAt).format("YYYY-MM-DD HH:mm"),
          (["admin", "accountant"].includes(role) && order.price) ? (
            toggle ? (
              `${parseFloat(order.priceInINR).toFixed(2)} INR`
            ) : (
              `${parseFloat(order.price).toFixed(2)} ${order.client.Wallet.currency.code}`
            )
          ) : (
            "-"
          ),
          order.dispatchStatus || "NYD",
          order.accountStatus,
          order.client?.name ? order.internalOrderStatus || "N/A" : "",
        ]),
      ];

      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Order Data");
      XLSX.writeFile(wb, "order-data.xlsx");
    } catch (err) {
      console.log(err);
    }
  };


  const handleUpdateItem = async (id) => {
    try {
      setEditBtnLoading(true);
      const data = updateProduct;
      data.id = id;
      if (!data.productName || !data.shipmentType || !data.country) {
        return errorToast("Fields should not be empty");
      }
      const res = await updateItemName(token, data);
      if (res.status === 200) {
        successToast(res.data.message);
        setEditBtnIndex(-1);
        setUpdateProduct({
          id: "",
          productName: "",
          shipmentType: "",
          country: "",
        });
      }
    } catch (err) {
      errorToast(err.response?.data?.message || "Something went wrong");
    } finally {
      setEditBtnLoading(false);
    }
  };

  const handleCloseUpdatePriceModal = () => {
    setUpdatePriceModal(false);
    setUpdatePriceObj({
      id: "",
      price: "",
      priceInINR: "",
    });
  };

  const handleUpdateBtn = (item) => {
    setUpdatePriceObj({
      id: item.id,
      price: item.price,
      priceInINR: item.priceInINR,
    });
    setUpdatePriceModal(true);
  };

  const handleUpdatePrice = async () => {
    try {
      if (
        !updatePriceObj.id ||
        !updatePriceObj.price ||
        !updatePriceObj.priceInINR
      ) {
        return errorToast("Please Fill all fields");
      }
      const res = await updatePrice(token, updatePriceObj);
      if (res.status === 200) {
        const updatedSelectedOrder = selectedOrderList.map((item) =>
          item.id === updatePriceObj.id
            ? { ...item, ...updatePriceObj, remark: "price adjusted" }
            : item
        );
        setSelectedOrderList(updatedSelectedOrder);
        successToast(res.data.message);
        handleCloseUpdatePriceModal();
        fetchData();
      }
    } catch (err) {
      errorToast(err.response?.data?.message || "Something went wrong");
    }
  };

  const handleUpdateFilenameClose = () => {
    setUpdateFilenameModal(false);
    setUpdateFilenameObj({
      orderId: "",
      newName: "",
    });
  };

  const handleUpdateFilenameBtn = async () => {
    try {
      setFilenameLoading(true);
      if (!updateFilenameObj.newName.trim()) {
        return errorToast("Filename is required");
      }
      const res = await updateFilename(token, updateFilenameObj);
      if (res.status === 200) {
        successToast("Filename updated successfully");
        // fetchData();
        const newList = orders.map((item) => {
          if (item.id === updateFilenameObj.orderId) {
            item.fileName = updateFilenameObj.newName;
          }
          return item;
        });
        setOrders(newList);
        handleUpdateFilenameClose();
      }
    } catch (err) {
      if (err.response?.status === 400) {
        errorToast(err.response?.data?.message);
      } else {
        errorToast("Something went wrong");
      }
    } finally {
      setFilenameLoading(false);
    }
  };

  const handleCancelOrder = async (id) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to cancel this order?"
      );
      if (confirmed) {
        const res = await cancelOrder(token, id);
        if (res.status === 200) {
          successToast(res.data.message);
          fetchData();
          showOrderHistory()
          return;
        }
      }
    } catch (err) {
      if (err.response?.status === 400) {
        return errorToast(err.response?.data?.message);
      } else {
        return errorToast("Something went wrong");
      }
    }
  };

  const handleDeleteOrder = async (fileName) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this order?"
      );
      if (confirmed) {
        const res = await deleteOrder(token, fileName)
        if (res.status === 200) {
          const newList = orders.filter(item => item.fileName !== fileName)
          setOrders(newList);
          successToast(res.data.message)
          return;
        }
      }
    } catch (err) {
      return errorToast(err.response?.data?.message || "Something went wrong");
    }
  }

  return (
    <Container>
      <div className="orderHistory">
        <h4 className="my-4">{t("Order History")}</h4>
        <div className="col-lg-5 mb-2 d-flex justify-content-between w-100">
          <div>
            <div className="d-flex align-items-center me-2">
              {role === "admin" && (
                <div className="me-4">
                  <Button onClick={handleDownloadList}>Download List</Button>
                </div>
              )}
              <Button
                variant="outline-danger"
                id="button-addon2"
                onClick={resetFilter}
              >
                <i className="fa-solid fa-rotate-left"></i>
              </Button>
              {["accountant", "admin"].includes(role) && (
                <Form.Check
                  className="ms-4"
                  type="switch"
                  id="inr-switch1"
                  label="Price In Inr"
                  checked={toggle}
                  onChange={() => {
                    setToggle(!toggle);
                  }}
                />
              )}
            </div>
          </div>
          <div>
            <b>{orders && (<div>{orders.length} / {totalCount}</div>)}</b>
          </div>
          <div>
            <Pagination>{items}</Pagination>
          </div>
        </div>
        <InfiniteScroll
          next={handleLoadMore}
          dataLength={orders.length || 0}
          hasMore={orders.length !== totalCount}
          loader={
            <div className="text-center mt-4">
              <p>Loading...</p>
            </div>
          }
          endMessage={
            <div style={{ textAlign: 'center', marginTop: "20px" }}>
              <b>{orders && (<div>{orders.length} / {totalCount}</div>)}</b>
            </div>
          }
        >
          <Table bordered hover responsive>
            <thead>
              <tr>
                <FilterSearch
                  name={t("File Name")}
                  table="orders"
                  column="fileName"
                  type="contains"
                  search={filterSearch}
                  ref={filterSearchRef1}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
                {!["client"].includes(role) && (
                  <FilterSearch
                    name={t("Client ID")}
                    table="orders"
                    column="userId"
                    type="contains"
                    search={filterSearch}
                    ref={filterSearchRef6}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                )}
                {!["client"].includes(role) && !!orders[0]?.client?.name && <th>Email</th>}
                <FilterSearch
                  name={t("Placed At")}
                  table="orders"
                  column="createdAt"
                  type="date"
                  search={filterSearch}
                  ref={filterSearchRef4}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
                {["admin", "accountant", "client"].includes(role) && <th>{t("Amount")}</th>}
                {
                  <FilterSearch
                    name={t("Dispatch Status")}
                    table="orders"
                    column="dispatchStatus"
                    type="equal"
                    search={filterSearch}
                    ref={filterSearchRef2}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                }
                {["admin", "accountant"].includes(role) && (
                  <FilterSearch
                    name="Account Status"
                    table="orders"
                    column="accountStatus"
                    type="equal"
                    search={filterSearch}
                    ref={filterSearchRef5}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                )}
                {role !== "client" && (
                  <FilterSearch
                    name="Internal Status"
                    table="orders"
                    column="internalOrderStatus"
                    type="equal"
                    search={filterSearch}
                    ref={filterSearchRef3}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                )}
                {["admin", "accountant"].includes(role) && <th>Action</th>}
              </tr>
            </thead>
            {!loading && orders && orders.length > 0 && (
              <tbody>
                {orders.map((order) => (
                  <tr
                    key={order.id}
                    role="button"
                    onClick={() => {
                      fetchOrderById(order);
                      setUserCurrency(order.client?.Wallet?.currency.code);
                    }}
                  >
                    <td>{order.fileName}</td>
                    {!["client"].includes(role) && !!order.userId && <td>{order.userId}</td>}
                    {!["client"].includes(role) && !!order.client?.email && <td>{order.client.email}</td>}
                    <td>{moment(order.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                    {/* {!!order.client?.name && <td>{order.price}</td>} */}
                    {["admin", "accountant", "client"].includes(role) && (
                      <td className="text-end">
                        {!!order.price ? (
                          <div>
                            {toggle ? (
                              <div>{parseFloat(order.priceInINR).toFixed(2)} INR <br />
                              </div>
                            ) : (
                              <div>{parseFloat(order.price).toFixed(2)} {order.client.Wallet.currency.code} <br />
                              </div>
                            )}
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>

                    )}
                    {role === "client" ? (
                      <td>
                        {dispatchStatusObj[order.dispatchStatus] ||
                          order.dispatchStatus}
                      </td>
                    ) : (
                      <td>{order.dispatchStatus}</td>
                    )}
                    {["admin", "accountant"].includes(role) && (
                      <td>{order.accountStatus}</td>
                    )}
                    {role !== "client" && (
                      <td>{order.internalOrderStatus || "N/A"}</td>
                    )}
                    {["admin", "accountant"].includes(role) && (
                      <td>
                        <Button
                          size="sm"
                          className="me-1 mb-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            setUpdateFilenameModal(true);
                            setUpdateFilenameObj({
                              orderId: order.id,
                              newName: order.fileName,
                            });
                          }}
                        >
                          <i className="fa-regular fa-pen-to-square"></i> filename
                        </Button>
                        {order.dispatchStatus === "hold" && (
                          <TooltipComponent
                            children={
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteOrder(order.fileName);
                                }}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            }
                            content={"Delete Order"}
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </InfiniteScroll>


        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          orders.length === 0 && (
            <div className="text-center">
              <h4>No Orders Yet</h4>
            </div>
          )
        )}
      </div>

      <Modal
        show={orderHistoryModal}
        onHide={showOrderHistory}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("Order History for")} {selectedOrder?.fileName}
            {
              selectedOrder?.dispatchStatus !== "canceled" && ["admin", "accountant", "dispatch_manager"].includes(role) && (
                <Button
                  size="sm"
                  variant="danger"
                  className="ms-2"
                  onClick={(e) => {
                    // e.stopPropagation();
                    handleCancelOrder(selectedOrder.id);
                  }}
                >
                  Cancel Complete Order
                </Button>
              )
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {role !== "client" && (
            <div className="row">
              <div className="col-md-5">
                <Form.Group className="mb-1 w-100">
                  <Form.Label>Internal Order Status</Form.Label>
                  <Form.Select
                    aria-label="Shipment Type"
                    value={internalOrderStatus}
                    onChange={(e) => setInternalOrderStatus(e.target.value)}
                  >
                    <option value="">N/A</option>
                    {internalOrderList.map((item, i) => (
                      <option value={item} key={"internalOrder" + i}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <div>
                  <Button
                    className="mb-2"
                    disabled={internalStatusLoading}
                    onClick={handleUpdate}
                  >
                    {internalStatusLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Update Status"
                    )}
                  </Button>
                  <div className="mb-3 ">
                    <span className="d-block text mb-1 light_class">
                      Mail Trigger Actions
                    </span>
                    <Button
                      disabled={sendEmailLoading}
                      onClick={handleSendMail}
                      className="m-1"
                    >
                      {sendEmailLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "Send Dispatch Mail"
                      )}
                    </Button>
                    <Button
                      className="m-1"
                      disabled={sendMasterEmailLoading}
                      onClick={handleMasterImportTrigger}

                    >
                      {sendEmailLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "Master Import Mail"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                {["admin", "dispatch_manager", "accountant"].includes(role) &&
                  (selectedOrder?.dispatchStatus === "hold" ||
                    selectedOrder?.accountStatus === "hold") && (
                    <div className="d-flex justify-content-end mb-2 light_class">
                      Reprocess Actions
                    </div>
                  )}
                {["admin", "dispatch_manager"].includes(role) &&
                  selectedOrder?.dispatchStatus === "hold" && (
                    <Button
                      onClick={reprocessDispatchHandle}
                      disabled={reprocessDispatchLoading}
                      className="float-end ms-2"
                    >
                      Reprocess Dispatch
                    </Button>
                  )}
                {["admin", "accountant"].includes(role) &&
                  selectedOrder?.accountStatus === "hold" && (
                    <Button
                      className="float-end text-end"
                      onClick={reprocessOrderHandle}
                      disabled={reprocessLoading}
                    >
                      Reprocess Invoice
                    </Button>
                  )}
              </div>
            </div>
          )}
          {modalLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th>{t("EDN")}</th>
                  <th>{t("Consignee Name")}</th>
                  <th>{t("Country")}</th>
                  <th>{t("Delivery Address")}
                    <span
                      onClick={() => {
                        setAllExpanded(!allExpanded)
                      }}
                      className="readButton"
                    >
                      <u>{allExpanded ? t(" Read Less") : t(" Read More")}</u>
                    </span>
                  </th>
                  <th>{t("Phone")}</th>
                  <th>{t("Pincode")}</th>
                  {["admin", "accountant", "dispatch_manager"].includes(
                    role
                  ) && <th>Product Code</th>}
                  <th>
                    {t("Product Name")}
                    <span
                      onClick={() => {
                        setAllExpanded(!allExpanded)
                      }}
                      className="readButton"
                    >
                      <u>{allExpanded ? t(" Read Less") : t(" Read More")}</u>
                    </span>
                  </th>
                  <th>{t("Shipment Type")}</th>
                  <th>{t("Tracking ID")}</th>
                  {["admin", "accountant", "client"].includes(role) && <th>{t("Amount")}</th>}
                  {["admin", "accountant"].includes(role) && <th>Account status</th>}
                  {["admin", "accountant", "dispatch_manager"].includes(
                    role
                  ) && <th>Remark</th>}
                  {role !== "client" && <th>Actions</th>}
                </tr>
              </thead>
              {selectedOrderList && selectedOrderList.length > 0 && (
                <tbody>
                  {selectedOrderList.map((item, index) => (
                    <tr key={item.id}>
                      <td role="button" className="text-primary">
                        <Link to={`/orderItemHistory/${item.id}`}
                          className={item.disabled ? "d-none" : ""}
                        >
                          {item.id}
                        </Link>
                        {!!item?.historyMetadata.length && ["admin", "accountant", "dispatch_manager"].includes(role) && (
                          <i
                            className="fa-solid fa-circle-info ms-2 download_Link"
                            onClick={(e) => {
                              e.stopPropagation();
                              setOrderItemHistoryInfo(true);
                              setInfoObj(item);
                            }}
                          ></i>
                        )}
                      </td>

                      <td>{item.consigneeName}</td>
                      {/* <td>{item.country}</td> */}
                      {selectedOrder.dispatchStatus === "hold" &&
                        item.trackingId !== "canceled" &&
                        (!!item.remark || !item.productCode) &&
                        editBtnIndex === index ? (
                        <td>
                          <InputGroup>
                            <Form.Control
                              value={item.country}
                              className="text-danger border-danger"
                              onChange={(e) => {
                                handleInputChange(e, index, "country");
                                setUpdateProduct({
                                  ...updateProduct,
                                  country: e.target.value,
                                });
                              }}
                            />
                          </InputGroup>
                        </td>
                      ) : (
                        <td>{item.country}</td>
                      )}
                      <td>
                        {" "}
                        <TruncateCell
                          text={item.deliveryAddress}
                          maxLength={allExpanded ? item.deliveryAddress.length : 10}
                        />
                      </td>
                      <td>{item.phone}</td>
                      <td>{item.pincode}</td>
                      {["admin", "accountant", "dispatch_manager"].includes(role) && (
                        item.productCode ?
                        <td className="text-primary">
                          <Link to={`/products/${item.productCode}`}
                            className="text-primary"
                          >
                            {item.productCode}
                          </Link>
                        </td> : <td>-</td>
                      )}
                      {selectedOrder.dispatchStatus === "hold" &&
                        item.trackingId !== "canceled" &&
                        (!!item.remark || !item.productCode) &&
                        editBtnIndex === index ? (
                        <td>
                          <InputGroup>
                            <Form.Control
                              value={item.productName}
                              className="text-danger border-danger"
                              onChange={(e) => {
                                handleInputChange(e, index, "productName");
                                setUpdateProduct({
                                  ...updateProduct,
                                  productName: e.target.value,
                                });
                              }}
                            />
                          </InputGroup>
                        </td>
                      ) : (
                        <td>
                          <TruncateCell
                            text={item.productName}
                            maxLength={allExpanded ? item.productName.length : 10}
                          />
                        </td>
                      )}
                      {selectedOrder.dispatchStatus === "hold" &&
                        item.trackingId !== "canceled" &&
                        (!!item.remark || !item.productCode) &&
                        editBtnIndex === index ? (
                        <td>
                          <InputGroup>
                            <Form.Control
                              value={item.shipmentType}
                              className="text-danger border-danger"
                              onChange={(e) => {
                                handleInputChange(e, index, "shipmentType");
                                setUpdateProduct({
                                  ...updateProduct,
                                  shipmentType: e.target.value,
                                });
                              }}
                            />
                          </InputGroup>
                        </td>
                      ) : (
                        <td>{item.shipmentType}</td>
                      )}
                      <td><TrackingDialog item={item} /></td>
                      {["admin", "accountant", "client"].includes(role) && (
                        <td className="text-end">
                          {!!item.price ? (
                            <div>
                              {toggle ? (
                                <div>{parseFloat(item.priceInINR).toFixed(2)} INR <br />
                                </div>
                              ) : (
                                <div>{parseFloat(item.price).toFixed(2)} {userCurrency} <br />
                                </div>
                              )}
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>

                      )}
                      {["admin", "accountant"].includes(
                        role
                      ) && <td>{item.accountingStatus || "-"}</td>}
                      {["admin", "accountant", "dispatch_manager"].includes(
                        role
                      ) && <td>{item.remark || "-"}</td>}
                      {role !== "client" && (
                        <td>
                          {item.trackingId !== "canceled" && (
                            <Button
                              className="m-2"
                              variant="danger"
                              size="sm"
                              onClick={() => {
                                handleDeleteModal();
                                setCancelItemid({
                                  ...cancelItemId,
                                  id: item.id
                                });
                              }}
                              disabled={cancelLoading}
                            >
                              cancel
                            </Button>
                          )}
                          {selectedOrder.dispatchStatus === "hold" &&
                            item.trackingId !== "canceled" &&
                            (!!item.remark || !item.productCode) &&
                            editBtnIndex !== index && (
                              <Button
                                className="m-2"
                                size="sm"
                                onClick={() => {
                                  setEditBtnIndex(index);
                                  setUpdateProduct({
                                    id: item.id,
                                    productName: item.productName,
                                    shipmentType: item.shipmentType,
                                    country: item.country,
                                  });
                                }}
                              >
                                Edit
                              </Button>
                            )}
                          {selectedOrder.dispatchStatus === "hold" &&
                            item.trackingId !== "canceled" &&
                            (!!item.remark || !item.productCode) &&
                            editBtnIndex === index && (
                              <Button
                                className="m-2"
                                size="sm"
                                onClick={() => handleUpdateItem(item.id)}
                                disabled={editBtnLoading}
                              >
                                Save
                              </Button>
                            )}
                          {["admin", "accountant"].includes(role) &&
                            !!item.productCode && (
                              <Button
                                className="m-2"
                                size="sm"
                                variant="success"
                                onClick={() => {
                                  handleUpdateBtn(item);
                                }}
                              >
                                <i className="fa-solid fa-money-bill-transfer"></i>
                              </Button>
                            )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
                <Button variant="primary" onClick={showOrderHistory}>
                  OK
                </Button>
              </Modal.Footer> */}
      </Modal>

      <Modal
        show={confirmDeleteModal}
        onHide={handleDeleteModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure, you want to cancel order item {cancelItemId.id} ?
          <Form>
            <Form.Check
              type={"checkbox"}
              id="notify-customer-cancelOrder"
              label="Notify Customer"
              checked={cancelItemId.notifyCustomer}
              onChange={(e) =>
                setCancelItemid({
                  ...cancelItemId,
                  notifyCustomer: e.target.checked,
                })
              }
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModal}>
            Close
          </Button>
          <Button variant="primary" onClick={cancelBtnHandle}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update price modal */}
      <Modal show={updatePriceModal} onHide={handleCloseUpdatePriceModal}>
        <Modal.Header closeButton>
          <Modal.Title>Adjust Price {updatePriceObj.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Price in {userCurrency}</Form.Label>
              <Form.Control
                type="number"
                placeholder=" Enter Price"
                value={updatePriceObj.price}
                onChange={(e) =>
                  setUpdatePriceObj({
                    ...updatePriceObj,
                    price: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Price in INR</Form.Label>
              <Form.Control
                type="number"
                placeholder=" Enter Price in INR"
                value={updatePriceObj.priceInINR}
                onChange={(e) =>
                  setUpdatePriceObj({
                    ...updatePriceObj,
                    priceInINR: e.target.value,
                  })
                }
              />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>Shipment Price in {userCurrency}</Form.Label>
              <Form.Control
                type="number"
                placeholder=" Enter Shipment Price"
                value={updatePriceObj.weightPrice}
                onChange={(e) =>
                  setUpdatePriceObj({
                    ...updatePriceObj,
                    weightPrice: e.target.value.trim(),
                  })
                }
              />
            </Form.Group> */}
            {/* <Form.Group className="mb-3">
              <Form.Label>Shipment Price in INR</Form.Label>
              <Form.Control
                type="number"
                placeholder=" Enter Shipment Price in INR"
                value={updatePriceObj.weightPriceInINR}
                onChange={(e) =>
                  setUpdatePriceObj({
                    ...updatePriceObj,
                    weightPriceInINR: e.target.value.trim(),
                  })
                }
              />
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdatePriceModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdatePrice}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal for updating filename */}
      <Modal show={updateFilenameModal} onHide={handleUpdateFilenameClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update File name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>File Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=" Enter File name"
                value={updateFilenameObj.newName}
                onChange={(e) =>
                  setUpdateFilenameObj({
                    ...updateFilenameObj,
                    newName: e.target.value,
                  })
                }
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUpdateFilenameClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleUpdateFilenameBtn}
            disabled={filenameLoading}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={orderItemHistoryInfo} onHide={() => {
        setInfoObj(null)
        setOrderItemHistoryInfo(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>OrderItem History Info for {infoObj?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered>
            <thead>
              <tr>
                <th>Event</th>
                <th>Email</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {
                infoObj?.historyMetadata?.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.event}</td>
                    <td>{item?.user?.email}</td>
                    <td>{item?.createdAt ? (moment(item?.createdAt).format("DD/MM/YY HH:mm")) : "-"}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          <hr />
          {
            infoObj ? <HistoryComment selectedOrderItem={infoObj} setInfoObj={setInfoObj} /> : ""
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {
            setInfoObj(null)
            setOrderItemHistoryInfo(false)
          }}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default OrderHistory;