import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { getLedgerInfo, getWalletInfo } from "../../Services/userService";
import { useSelector } from "react-redux";
import "./wallet.css";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { useLocale } from "../../lib/customHooks/useLocale";

const WalletDetails = () => {
  const { t } = useLocale("common")
  const [amount, setAmount] = useState(0);
  // const [creditLimit, setCreditLimit] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("");
  const [ledgerTable, setLedgerTable] = useState(null);
  const token = useSelector((state) => state.token);

  const getWalletInformation = async () => {
    try {
      const res = await getWalletInfo(token);
      const wallet = res.data.walletInfo;
      setAmount(wallet.balanceInLocal);
      // setCreditLimit(wallet.creditLimit);
      setCurrencyCode(wallet.currency.code);
    } catch (err) {
      console.log(err);
    }
  };

  const getLedgerInformation = async () => {
    try {
      const res = await getLedgerInfo(token);
      setLedgerTable(res.data.ledgerInfo);
      setTotalCount(res.data.totalCount);
    } catch (err) {
      console.log(err);
    }
  };

  const handleLoadMore = async () => {
    try {
      const res = await getLedgerInfo(token, { offSet: ledgerTable.length });
      let table = [...ledgerTable];
      table.push(...res.data.ledgerInfo);
      setLedgerTable(table);
      setTotalCount(res.data.totalCount);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWalletInformation();
    getLedgerInformation();
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <div className="d-flex justify-content-between">
        <div className="mt-4">
          <h4>{t("Wallet Information")}</h4>
        </div>
        <div className="d-flex justify-content-end align-content-end mt-4">
          <div className="me-4 fw-bold">
            <h5 className="fw-bold">
              {t("Balance")}: {amount} {currencyCode}
            </h5>
          </div>
          {/* <div className="ms-2">
            <h5 className="fw-bold">
              Credit Limit: {creditLimit} {currencyCode}
            </h5>
          </div> */}
        </div>
      </div>
      {ledgerTable && !!ledgerTable.length && (
        <Table bordered responsive className="mt-4">
          <thead>
            <tr>
              <th>{t("RefDate (YY/MM/DD)")}</th>
              <th>{t("Order File ID - SN")}</th>
              <th>{t("Transaction Type")}</th>
              <th>{t("Order Particular Description")}</th>
              <th>{t("Amount")} {currencyCode}</th>
            </tr>
          </thead>
          <tbody>
            {ledgerTable.map((item, index) => (
              <tr key={index}>
                <td>{moment(item.referenceDate).format("YY/MM/DD")}</td>
                <td>
                  {item.orderItemId ? (
                    <Link
                      to={`/orderItemHistory/${item.orderItemId}`}
                      className={item.disabled ? "d-none" : ""}
                    >
                      {item.orderItemId}
                    </Link>
                  ) : (
                    "Amount " + item.type
                  )}
                </td>
                <td
                  className={
                    item.type === "credit" ? "text-success" : "text-danger"
                  }
                >
                  {item.type}
                </td>
                <td>
                  {item.orderItem?.productName
                    ? `${item.orderItem.productName}[${item.orderItem.shipmentType}]`
                    : item.remark || "-"}
                </td>
                <td
                  className={
                    (item.type === "credit" ? "text-success" : "text-danger") + " text-end"
                  }
                >
                  {parseFloat(item.amountInLocal).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {ledgerTable && !ledgerTable.length && (
        <div className="text-center mt-5">
          <h5>No Data Found</h5>
        </div>
      )}
      {ledgerTable && !!ledgerTable.length && (
        <div>
          {ledgerTable.length} / {totalCount}
        </div>
      )}
      <div className="text-center my-2">
        {ledgerTable && totalCount > ledgerTable.length ? (
          <Button variant="dark" onClick={handleLoadMore}>
            {t("Load More")}
          </Button>
        ) : null}
      </div>
    </Container>
  );
};

export default WalletDetails;
