import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Container,
    Form,
    InputGroup,
    Modal,
    Table,
    Spinner,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { errorToast, successToast } from "../../Services/toastService";
import "./adminProducts.css";
import {
    addProduct,
    getAllProducts,
    searchProduct,
    updateProduct,
    uploadProducts,
    fetchBasedProductById,
    triggerMSBCProductsCron,
    priceListDownload,
    getAllClients,
} from "../../Services/adminService";
import { TruncateCell } from "../../SharedComponents/truncateCell/truncateCell";
import moment from "moment";
import FilterSearch from "../../SharedComponents/filterSearch/filterSearch";
import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { getPriceSlabList } from "../../Services/shipmentService";
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setProductTable } from "../../State/store";

const AdminProducts = () => {
    const productTableObj = useSelector((state) => state.productTable);
    const fileInputRef = useRef(null);
    const [productsFile, setProductsFile] = useState(null);
    const [uploadModal, setUploadModal] = useState(false);
    const [products, setProducts] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [cronTriggerLoading, setCronTriggerLoading] = useState(false);
    const [uploadProductLoading, setUploadProductLoading] = useState(false);
    const [addProductModal, setAddProductModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({
        id: "",
        name: "",
        weight: "",
        boxNumber: "",
        price: "",
    });
    const [updateModal, setUpdateModal] = useState(false);
    const token = useSelector((state) => state.token);
    const [allExpanded, setAllExpanded] = useState(false);
    const [allSubExpanded, setAllSubExpanded] = useState(false);
    const [baseProduct, setBaseProduct] = useState(null);
    const [baseProductModal, setBaseProductModal] = useState(false);
    const [errTable, setErrTable] = useState(null);
    const [searchObj, setSearchObj] = useState([]);
    const filterSearchRef1 = useRef(null);
    const filterSearchRef2 = useRef(null);
    const filterSearchRef3 = useRef(null);
    const filterSearchRef4 = useRef(null);
    const filterSearchRef5 = useRef(null);
    const filterSearchRef6 = useRef(null);
    const filterSearchRef7 = useRef(null);
    const filterSearchRef8 = useRef(null);
    const filterSearchRef9 = useRef(null);
    const filterSearchRef10 = useRef(null);
    const [reqObj, setReqObj] = useState({
        currencyID: "",
        limit: 10,
        offSet: null
    })
    var refArray = [
        filterSearchRef1,
        filterSearchRef2,
        filterSearchRef3,
        filterSearchRef4,
        filterSearchRef5,
        filterSearchRef6,
        filterSearchRef7,
        filterSearchRef8,
        filterSearchRef9,
        filterSearchRef10,
    ];
    const dispatch = useDispatch();
    const [tableReorderModal, setTableReorderModal] = useState(false);
    const [tableReorderState, setTableReorderState] = useState(productTableObj);
    const [orderBy, setOrderBy] = useState({ "name": "asc" })
    const [priceListModal, setPriceListModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const [priceDownloadLoading, setPriceDownloadLoading] = useState(false)
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)
    const [clientList, setClientList] = useState([])
    const [downloadTable, setDownloadTable] = useState(null)
    const [tableHeaders, setTableHeaders] = useState([])
    const [downloadTableHeader, setDownloadTableHeader] = useState(null)
    const navigate = useNavigate();
    const [clientFilterModal, setClientFilterModal] = useState(false)
    const [codeList, setCodeList] = useState([]);
    const [clientFilter, setClientFilter] = useState(
      localStorage.getItem("prodClientFilter")
        ? JSON.parse(localStorage.getItem("prodClientFilter"))
        : []
    );
    const [clientFilterInput, setClientFilterInput] = useState({
        id: "",
        clientName: "",
        slabID: "",
        shipmentName: ""
    })
    const [priceBreakdownValue, setPriceBreakdownValue] = useState([]);
    const [priceBreakdownModal, setPriceBreakdownModal] = useState(false)
    const [baseProductItem, setBaseProductItem] = useState(null)
    const [errorModal, setErrorModal] = useState(false)
    const [errorModalData, setErrorModalData] = useState([])
    let items = [];
    let paginationNum = [10, 25, 50];
    paginationNum.forEach(number => {
        items.push(
            <Pagination.Item
                key={number}
                active={number === reqObj.limit}
                onClick={() => setReqObj({ ...reqObj, limit: number })}
            >
                {number}
            </Pagination.Item>
        );
    });

    let { id } = useParams();

    const upload = async () => {
        try {
            setUploadProductLoading(true);
            const formData = new FormData();
            formData.append("xlsx", productsFile);
            const response = await uploadProducts(formData, token);
            if (response.status === 200) {
                successToast(response.data.message);
                searchProductBtn();
                setUploadModal(false);
            }
        } catch (err) {
            if (err.response?.status === 404) {
                setProducts(null);
                setTotalCount(0);
                setErrTable(err.response?.data);
                setUploadModal(false);
                errorToast("Some Ids may not present!!!");
            }
            if (!err.response) {
                return errorToast("Something went wrong");
            }
            errorToast(err.response.data.message);
        } finally {
            setUploadProductLoading(false);
        }
    };

    const updateProductDialog = (product) => {
        setSelectedProduct(product);
        setUpdateModal(true);
    };

    const handleAddProduct = async (e) => {
        e.preventDefault();
        try {
            const response = await addProduct(token, selectedProduct);
            if (response.status === 200) {
                successToast("Product Added Successfully");
                updateModalShow();
                setSelectedProduct({
                    id: "",
                    name: "",
                    weight: "",
                    boxNumber: "",
                    price: "",
                });
                searchProductBtn();
            }
        } catch (err) {
            if (!err.response) {
                return errorToast("Something went wrong");
            }
            if (err.response?.status === 400) {
                errorToast(err.response.data.message);
            }
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            await updateProduct(token, selectedProduct);
            successToast("Product updated successfully");
            setUpdateModal(false);
            const updatedProducts = products.map((product) => {
                if (product.id === selectedProduct.id) {
                    return { ...product, ...selectedProduct };
                }
                return product;
            });
            setProducts(updatedProducts);
            setSelectedProduct({
                id: "",
                name: "",
                weight: "",
                boxNumber: "",
                price: "",
            });
        } catch (error) {
            errorToast("Failed to update product");
            console.error(error);
        }
    };

    const searchWithFilter = async () => {
        try {
            let newObj = reqObj
            newObj.offSet = null
            const response = await searchProduct(token, searchObj, newObj, orderBy, clientFilter);
            const data = response.data.products;
            if (data.length === 0) {
                errorToast("No product found with this name");
            }
            setProducts(data);
            let keys = {};
            data.forEach((items) => {
                if (Object.keys(items).length > Object.keys(keys).length) {
                    keys = items;
                }
            })
            const newHeader = Object.keys(keys).filter((item) => (
                item.startsWith("|") && !item.endsWith("|priceBreakdown|")
            ));
            setTableHeaders(newHeader)
            setTotalCount(response.data.productCount);
        } catch (err) {
            if (!err.response) {
                return errorToast("Something went wrong");
            }
            errorToast(err.response?.data.message);
        }
    }

    const searchProductBtn = async () => {
        try {
            if (id) {
                let newObj = [
                    {
                        column: "id",
                        type: "contains",
                        value: id
                    }
                ]
                const response = await searchProduct(token, newObj, reqObj, orderBy, clientFilter);
                const data = response.data.products;
                if (data.length === 0) {
                    errorToast("No product found with this name");
                }
                setProducts(data);
                let keys = {};
                data.forEach((items) => {
                    if (Object.keys(items).length > Object.keys(keys).length) {
                        keys = items;
                    }
                })
                const newHeader = Object.keys(keys).filter((item) => (
                    item.startsWith("|") && !item.endsWith("|priceBreakdown|")
                ));
                setTableHeaders(newHeader)
                setTotalCount(response.data.productCount);
            } else {
                const response = await searchProduct(token, searchObj, reqObj, orderBy, clientFilter);
                const data = response.data.products;
                if (data.length === 0) {
                    errorToast("No product found with this name");
                }
                setProducts(data);
                let keys = {};
                data.forEach((items) => {
                    if (Object.keys(items).length > Object.keys(keys).length) {
                        keys = items;
                    }
                })
                const newHeader = Object.keys(keys).filter((item) => (
                    item.startsWith("|") && !item.endsWith("|priceBreakdown|")
                ));
                setTableHeaders(newHeader)
                setTotalCount(response.data.productCount);
            }
        } catch (err) {
            if (!err.response) {
                return errorToast("Something went wrong");
            }
            errorToast(err.response?.data.message);
        }
    };

    const handleLoadMore = async () => {
        try {
            setLoadMoreLoading(true)
            let newObj = { ...reqObj, offSet: products.length }
            const response = await searchProduct(token, searchObj, newObj, orderBy, clientFilter);
            const data = response.data.products
            let table = [...products];
            table.push(...response.data.products);
            let keys = {};
            data.forEach((items) => {
                if (Object.keys(items).length > Object.keys(keys).length) {
                    keys = items;
                }
            })
            const newHeader = Object.keys(keys).filter((item) => (
                item.startsWith("|") && !item.endsWith("|priceBreakdown|")
            ));
            setTableHeaders(newHeader)
            setProducts(table);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadMoreLoading(false)
        }
    };

    const getAllProductList = async () => {
        try {
            const response = await getAllProducts(token);
            let table = response.data.products;
            setProducts(table);
        } catch (err) {
            console.log(err);
        }
    };

    const backBtn = () => {
        searchProductBtn();
        setErrTable(null);
    };

    const userData = async () => {
        try {
            const res = await getAllClients(token)
            // console.log(res.data.clients)
            setClientList(res.data.clients)
        } catch (err) {
            return errorToast("Something went wrong")
        }
    }

    useEffect(() => {
        searchProductBtn();
        // eslint-disable-next-line
    }, [searchObj, reqObj.currencyID, reqObj.limit, orderBy]);

    useEffect(() => {
        localStorage.setItem("prodClientFilter", JSON.stringify(clientFilter))
        // eslint-disable-next-line
    }, [clientFilter]);

    useEffect(() => {
        userData()
        getPriceSlabs()
        // eslint-disable-next-line
    }, []);

    const generateTable = async () => {
        try {
            setIsLoading(true)
            const response = await getAllProducts(token, null, true);
            const jsonData = response.data.products;
            const excludedFields = ["createdAt", "updatedAt", "UOMs", "alternativeName", "AssemblyProducts"];

            let filteredKeys;
            filteredKeys = Object.keys(jsonData[0]).filter((key) => !excludedFields.includes(key));

            const newData = jsonData.map(item => {
                return filteredKeys.reduce((obj, key) => {
                    obj[key] = item[key];
                    return obj;
                }, {});
            });
            setDownloadTableHeader(filteredKeys)
            setDownloadTable(newData)
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false)
        }
    }

    const downloadTableAsTsv = () => {
        try {
            setIsLoading(true);
            const dataRows = downloadTable.map(item => {
                return downloadTableHeader.map(header => {
                    if (typeof item[header] === 'boolean') {
                        return item[header] ? 'true' : 'false';
                    } else {
                        return item[header];
                    }
                })
            })
            dataRows.unshift(downloadTableHeader);
            const worksheet = XLSX.utils.aoa_to_sheet(dataRows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
            const excelData = XLSX.write(workbook, { type: 'binary' });

            const blob = new Blob([stringToArrayBuffer(excelData)], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'products.xlsx';

            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
        } catch (error) {
            errorToast("Something Went Wrong");
        } finally {
            setIsLoading(false);
        }
    };

    async function updateProductList() {
        try {
            if (
                window.confirm("Do you really want to update products from MSBC?") ===
                true
            ) {
                setCronTriggerLoading(true);
                let res = await triggerMSBCProductsCron(token);
                successToast(res.data.message || "Successfully Updated!");
                setCronTriggerLoading(false);
                resetFilter()
            }
        } catch (err) {
            if (err.response?.status === 400) {
                setErrorModal(true);
                setErrorModalData(err.response?.data?.message || "Something went wrong");
                console.log(err.response?.data?.message)
            } else {
                errorToast(err.response?.data?.message || "Something went wrong!");
            }
            setCronTriggerLoading(false);
        }
    }

    const updateModalShow = () => {
        setUpdateModal(!updateModal);
    };

    const getFetchBasedProductById = async (item, productIDCache) => {
        try {
            const response = await fetchBasedProductById(
                token,
                item.id,
                productIDCache
            );
            let table = response.data.assemblyFetchData;
            setBaseProduct(table);
        } catch (err) {
            console.log(err);
        }
        setBaseProductModal(true);
    };

    const filterSearch = async (obj, isSelected) => {
        let current = searchObj;
        current = current.filter((item) => item.column !== obj.column);
        setReqObj((cur) => {
            let newReqObj = { ...cur }
            newReqObj.offSet = null
            return newReqObj
        })
        if (isSelected) {
            setSearchObj(current)
        } else {
            current.push(obj);
            setSearchObj(current);
        }
    };

    const resetFilter = () => {
        if (id) {
            navigate("/products");
        }
        setSearchObj([]);
        setOrderBy({ "name": "asc" })
        setReqObj({
            currencyID: "",
            limit: 10,
            offSet: null
        })
        filterSearchRef1.current && filterSearchRef1.current.resetFilter();
        filterSearchRef2.current && filterSearchRef2.current.resetFilter();
        filterSearchRef3.current && filterSearchRef3.current.resetFilter();
        filterSearchRef4.current && filterSearchRef4.current.resetFilter();
        filterSearchRef5.current && filterSearchRef5.current.resetFilter();
        filterSearchRef6.current && filterSearchRef6.current.resetFilter();
        filterSearchRef7.current && filterSearchRef7.current.resetFilter();
        filterSearchRef8.current && filterSearchRef8.current.resetFilter();
        filterSearchRef9.current && filterSearchRef9.current.resetFilter();
        filterSearchRef10.current && filterSearchRef10.current.resetFilter();
        // setDispatchStatus("Select Status");
    };

    const handlePriceListModal = () => {
        setPriceListModal(false)
        setSelectedUser(null);
    }

    const handlePriceListDownload = async () => {
        try {
            setPriceDownloadLoading(true)
            if (!selectedUser) {
                return errorToast("Please select User")
            }
            const res = await priceListDownload(token, selectedUser)
            setDownloadTable(res.data.priceList)
            let header = {};
            res?.data?.priceList?.forEach(product => {
                if (Object.keys(product).length > Object.keys(header).length) {
                    header = product;
                }
            });
            let arr = [];
            Object.keys(header).forEach(product => {
                if (!product.endsWith("|priceBreakdown|")) {
                    arr.push(product)
                }
            });
            setDownloadTableHeader(arr);
            setPriceListModal(false);
        } catch (err) {
            console.log(err)
        } finally {
            setPriceDownloadLoading(false)
        }
    }

    const handleClientFilter = () => {
        setClientFilterModal(false)
        setClientFilterInput({
            id: "",
            slabID: ""
        })
    }

    const clientFilterTableHandle = () => {
        if (!clientFilterInput.id || !clientFilterInput.slabID || !clientFilterInput.id.length || !clientFilterInput.slabID.length) {
            return errorToast("Fields are required");
        }
        const existingItem = clientFilter?.find((item) => {
            return item.id === clientFilterInput.id && item.slabID === clientFilterInput.slabID;
        });

        if (existingItem) {
            return errorToast("Data already exists");
        }
        let newTable = [...clientFilter, clientFilterInput];
        setClientFilter(newTable);
        setClientFilterInput({
            id: "",
            slabID: ""
        })
    }

    const getPriceSlabs = async () => {
        try {
            const res = await getPriceSlabList(token);
            setCodeList(res.data.data);
        } catch (error) {
            errorToast("something went wrong");
        }
    };

    const handleFilterTable = (i) => {
        let filteredData = [...clientFilter];
        filteredData.splice(i, 1)
        setClientFilter(filteredData)
    }

    // const downloadTableData = () => {
    //     if (!products) {
    //         errorToast('Table is empty');
    //         return;
    //     }
    //     let headers = ["id", "name", "compositionName", "weight", "boxNumber", "price", "unitOfMeasure", "isAssembly", "isOrderable"];
    //     headers = [...headers, ...tableHeaders];

    //     // Map products and extract values based on headers
    //     const data = [headers, ...products.map(product => {
    //         return headers.map(header => product[header]);
    //     })];

    //     const worksheet = XLSX.utils.aoa_to_sheet(data);
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
    //     const excelData = XLSX.write(workbook, { type: 'binary' });

    //     const blob = new Blob([stringToArrayBuffer(excelData)], { type: 'application/octet-stream' });
    //     const url = window.URL.createObjectURL(blob);

    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = 'products.xlsx';

    //     document.body.appendChild(link);
    //     link.click();

    //     window.URL.revokeObjectURL(url);
    // };

    const stringToArrayBuffer = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    };

    const handleArrowIconClicked = (index, direction) => {
        const updatedFilterObj = [...productTableObj];
        if (direction === "left" && index > 0) {
            [updatedFilterObj[index], updatedFilterObj[index - 1]] = [
                updatedFilterObj[index - 1],
                updatedFilterObj[index],
            ];
        } else if (direction === "right" && index < productTableObj.length - 1) {
            [updatedFilterObj[index], updatedFilterObj[index + 1]] = [
                updatedFilterObj[index + 1],
                updatedFilterObj[index],
            ];
        }
        dispatch(
            setProducts({
                productTable: productTableObj,
            })
        );
    };

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = [...tableReorderState];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setTableReorderState(items);
    }

    const handleDisabledButton = (i) => {
        const updatedData = tableReorderState.map((item) =>
            item.key === i.key ? { ...item, disabled: !item.disabled } : item
        );
        setTableReorderState(updatedData);
    };

    const handlePositionChange = (startIndex, targetIndex) => {
        const updatedTable = [...tableReorderState];
        // remove the element from the current index
        const [removedElement] = updatedTable.splice(startIndex, 1);
        // insert the current removed element at targetIndex
        updatedTable.splice(targetIndex, 0, removedElement);
        setTableReorderState(updatedTable);
    };

    const handleTableSetting = () => {
        if (!tableReorderState.length) {
            setTableReorderModal(false);
            return;
        }
        dispatch(
            setProductTable({
                productTable: tableReorderState,
            })
        );
        setTableReorderModal(false);
    };

    const handleDownloadList = async () => {
        try {
            // const activeColumns = productTableObj.filter((item) => !item.disabled);
            const activeColumns = [...productTableObj.filter((item) => !item.disabled), ...tableHeaders];
            const data = [
                activeColumns.map((item) => item.key || item),
                ...products.map((product) => {
                    return activeColumns.map((item) => {
                        const processedValue =
                            item.value === "price" && product[item.value] === null
                                ? "-"
                                : item.value === "boxNumber" && product[item.value] === null
                                    ? "-"
                                    : item.value === "weight" && product[item.value] === null
                                        ? "-"
                                        : product[item.value || item];
                        return processedValue;
                    });
                }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data); // Convert data array to worksheet
            const wb = XLSX.utils.book_new(); // Create workbook
            XLSX.utils.book_append_sheet(wb, ws, "Products"); // Add worksheet to workbook
            XLSX.writeFile(wb, "products.xlsx"); // Trigger file download
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <Container fluid>
            {
                !!downloadTable ? (
                    <>
                        <div className="mt-4 mb-4">
                            <Button
                                className="me-4"
                                onClick={() => {
                                    // resetFilter()
                                    setDownloadTable(null)
                                    setDownloadTableHeader(null)
                                    setSelectedUser(null)
                                }}
                            >
                                <i className="fa-solid fa-angle-left"></i> Go Back
                            </Button>
                            <Button onClick={downloadTableAsTsv} disabled={isLoading}>
                                <i className="fa-solid fa-download"></i> Download
                            </Button>

                        </div>
                        <Table bordered responsive hover>
                            <thead>
                                <tr>
                                    {
                                        downloadTableHeader.map((header, i) => (
                                            <th key={i}>{header}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    downloadTable.map((item, index) => (
                                        <tr key={index}>
                                            {downloadTableHeader.map((key, i) => (
                                                <td key={i}>
                                                    {
                                                        item[key] === undefined || item[key] === "" || item[key] === null ?
                                                            "-" :
                                                            (
                                                                typeof item[key] === 'boolean' ? (item[key] ? 'True' : 'False') :
                                                                    item[key]
                                                            )
                                                    }
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </>
                ) : (
                    <>
                        <h4 className="my-4">
                            {
                                (errTable && errTable.length) ? "Price Preview" : "Product Lists"
                            }
                        </h4>
                        {!errTable && (
                            <div className="row mb-3">
                                <div className="col-md-12 mt-1">
                                    <Button
                                        className="m-1"
                                        variant="outline-secondary"
                                        onClick={() => {
                                            setUploadModal(!uploadModal);
                                            setAddProductModal(!addProductModal);
                                        }}
                                    >
                                        <i className="fa-regular fa-pen-to-square"></i> Update Price
                                    </Button>
                                    <Button
                                        className="m-1"
                                        variant="outline-secondary"
                                        onClick={() => {
                                            setPriceListModal(true)
                                        }}
                                    >
                                        ₹ Price List
                                    </Button>
                                    <Button
                                        className="m-1"
                                        variant="outline-secondary"
                                        onClick={generateTable}
                                        disabled={isLoading}
                                    >
                                        <i className="fa-solid fa-download"></i>
                                        {isLoading ? (
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        ) : (
                                            " list"
                                        )}
                                    </Button>
                                    <Button
                                        variant="outline-secondary"
                                        onClick={handleDownloadList}
                                    >
                                        <i className="fa-solid fa-download"></i> View
                                    </Button>
                                    <Button
                                        className="m-1"
                                        variant="outline-warning"
                                        onClick={updateProductList}
                                        disabled={cronTriggerLoading}
                                    >
                                        <i className="fa-solid fa-arrows-rotate"></i>
                                        {cronTriggerLoading ? (
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        ) : (
                                            " Update From MSBC"
                                        )}
                                    </Button>
                                    <Button
                                        variant="outline-secondary"
                                        id="button-addon2"
                                        onClick={() => setClientFilterModal(true)}
                                        className="m-1"
                                    >
                                        Client Filter
                                    </Button>
                                    <Button
                                        className="m-1"
                                        onClick={searchWithFilter}
                                        variant="outline-success"
                                    >
                                        <i className="fa-solid fa-rotate-right"></i>
                                    </Button>
                                    <Button
                                        variant="outline-danger"
                                        id="button-addon2"
                                        onClick={resetFilter}
                                        className="m-1"
                                    >
                                        <i className="fa-solid fa-rotate-left"></i>
                                    </Button>
                                    <Button
                                        variant="outline-secondary"
                                        id="button-addon3"
                                        onClick={() => setTableReorderModal(true)}
                                        className="ms-2"
                                    >
                                        Table Settings
                                    </Button>
                                </div>
                            </div>
                        )}
                        {products && !!products.length && !errTable && (
                            <Table bordered responsive hover>
                                <thead>
                                    <tr>
                                        {productTableObj?.map(
                                            (item, i) =>
                                                <FilterSearch
                                                    className={item.disabled ? "d-none" : ""}
                                                    key={i}
                                                    name={item.key}
                                                    table="products"
                                                    column={item.value}
                                                    type={item.type}
                                                    search={filterSearch}
                                                    ref={refArray[i]}
                                                    orderBy={orderBy}
                                                    setOrderBy={setOrderBy}
                                                    handleIconClicked={(direction) =>
                                                        handleArrowIconClicked(i, direction)
                                                    }
                                                    toExpand={
                                                        item.value === "name" || item.value === "compositionName"
                                                            ? () => setAllExpanded(!allExpanded)
                                                            : undefined
                                                    }
                                                />
                                        )}
                                        <th>Action</th>
                                        {
                                            tableHeaders?.map((item) => (
                                                <th key={item}>{item.slice(1)}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((item, index) => (
                                        <tr key={index}>
                                            {productTableObj?.map((i, index) => (
                                                <td key={index} className={i.disabled ? "d-none" : ""}>
                                                    {i.value === "isAssembly" ? (
                                                        item.isAssembly === true ? "True" : "False"
                                                    ) : i.value === "name" ? (
                                                        <TruncateCell
                                                            text={item.alternativeName ? item.alternativeName : item.name}
                                                            maxLength={allExpanded ? item.name.length : 20}
                                                        />
                                                    ) : i.value === "price" ? (
                                                        <span
                                                            onClick={() => {
                                                                if (item.isAssembly) {
                                                                    setBaseProductItem(item)
                                                                    getFetchBasedProductById(item);
                                                                }
                                                            }}
                                                            role="button"
                                                            style={{ cursor: item.isAssembly ? "pointer" : "default" }}
                                                        >
                                                            {item.isAssembly ? (
                                                                <span className="text-primary">Assembly</span>
                                                            ) : (
                                                                item.price ? `${item.price} ${item.currency || "INR"}` : "-"
                                                            )}
                                                        </span>
                                                    ) : i.value === "isOrderable" ? (
                                                        item.isOrderable === true ? "True" : "False"
                                                    ) : i.value === "compositionName" ? (
                                                        <TruncateCell
                                                            text={item.compositionName}
                                                            maxLength={allExpanded ? item.compositionName.length : 20}
                                                        />
                                                    ) : i.value === "unitOfMeasure" ? (
                                                        <span
                                                            onClick={() => {
                                                                setBaseProductItem(item)
                                                                setBaseProductModal(true)
                                                            }}
                                                            role="button"
                                                        >
                                                            <span className="text-primary">{item[i.value]}</span>
                                                        </span>
                                                    )
                                                        : (
                                                            <>{item[i.value] ?? "-"}</>
                                                        )}
                                                </td>
                                            ))}
                                            <td key={item.id}>
                                                {item.isAssembly ? (
                                                    <>-</>
                                                ) : (
                                                    <Button
                                                        className="me-2"
                                                        size="sm"
                                                        variant="primary"
                                                        onClick={() => {
                                                            updateProductDialog(item);
                                                            setAddProductModal(false);
                                                        }}
                                                    >
                                                        Update
                                                    </Button>
                                                )}
                                            </td>
                                            {tableHeaders?.map((k) =>
                                                item[k] !== "-" ? (
                                                    <th
                                                        key={k}
                                                        role="button"
                                                        className="text-primary"
                                                        onClick={() => {
                                                            setPriceBreakdownValue(
                                                                item[k + "|priceBreakdown|"]
                                                            );
                                                            setPriceBreakdownModal(true);
                                                        }}
                                                    >
                                                        {item[k]}
                                                    </th>
                                                ) : (
                                                    <th key={k}>{item[k]}</th>
                                                )
                                            )}
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        )}
                        {!errTable && products && !!products.length && (
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    {products.length} / {totalCount}
                                </div>
                                <div className="mt-2">
                                    <Pagination>{items}</Pagination>
                                </div>
                            </div>
                        )}
                        {errTable && (
                            <>
                                <Button variant="dark" className="mb-2" onClick={backBtn}>
                                    Back
                                </Button>
                                <div>
                                    <p className="text-danger">*Some Ids may not present in the list</p>
                                </div>
                            </>
                        )}
                        <div className="text-center my-2">
                            {!errTable && products && totalCount > products.length ? (
                                <Button variant="dark" onClick={() => {
                                    setReqObj({ ...reqObj, offSet: products.length })
                                    handleLoadMore();
                                }}
                                    disabled={loadMoreLoading}
                                >
                                    {loadMoreLoading ? (
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    ) : (
                                        "Load More"
                                    )}
                                </Button>
                            ) : null}
                        </div>
                        {!errTable && (
                            <div className="my-2">
                                <Button variant="dark" onClick={getAllProductList}>
                                    Get All
                                </Button>
                            </div>
                        )}

                        {errTable && errTable.length && (
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {errTable.map((item) => (
                                        <tr key={item.id}>
                                            <td
                                                className={`${item.isValid === false ? "text-danger" : ""}`}
                                            >
                                                {item.id}
                                            </td>
                                            <td
                                                className={`${item.isValid === false ? "text-danger" : ""}`}
                                            >
                                                {item.price}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </>
                )
            }


            <Modal
                show={uploadModal}
                onHide={() => setUploadModal(!uploadModal)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formFile" className="file-inp">
                        <Form.Label>Update Price (per base quantity in INR)</Form.Label>
                        <Form.Control
                            ref={fileInputRef}
                            type="file"
                            accept=".xlsx, .csv, .tsv, .xls, .txt"
                            onChange={(e) => {
                                setProductsFile(e.target.files[0]);
                            }}
                        />
                    </Form.Group>
                    <small className="text-danger">
                        Upload only xls, xlsx or tsv file <br />
                        <a
                            className="text-primary download_Link"
                            href="/price file.xlsx"
                            download="Update Price File"
                        >
                            Download Sample File
                        </a>
                    </small>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={upload}
                        disabled={uploadProductLoading}
                    >
                        {uploadProductLoading ? (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : (
                            "Save"
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={updateModal}
                onHide={() => {
                    updateModalShow();
                    setSelectedProduct({
                        id: "",
                        name: "",
                        weight: "",
                        boxNumber: "",
                    });
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {addProductModal ? "Add Product" : "Update Product"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        onSubmit={addProductModal ? handleAddProduct : handleUpdate}
                        autoComplete="off"
                    >
                        {addProductModal && (
                            <Form.Group>
                                <Form.Label>Id</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={selectedProduct.id || ""}
                                    onChange={(e) =>
                                        setSelectedProduct({
                                            ...selectedProduct,
                                            id: e.target.value,
                                        })
                                    }
                                />
                            </Form.Group>
                        )}
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                disabled
                                value={selectedProduct.name || ""}
                                onChange={(e) =>
                                    setSelectedProduct({
                                        ...selectedProduct,
                                        name: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Weight (Gram)</Form.Label>
                            <Form.Control
                                type="text"
                                disabled
                                value={selectedProduct.weight || ""}
                                onChange={(e) =>
                                    setSelectedProduct({
                                        ...selectedProduct,
                                        weight: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Box Number</Form.Label>
                            <Form.Control
                                type="text"
                                disabled
                                value={selectedProduct.boxNumber || ""}
                                onChange={(e) =>
                                    setSelectedProduct({
                                        ...selectedProduct,
                                        boxNumber: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Label>Price</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>₹</InputGroup.Text>
                            <Form.Control
                                type="text"
                                value={selectedProduct.price || ""}
                                onChange={(e) =>
                                    setSelectedProduct({
                                        ...selectedProduct,
                                        price: e.target.value,
                                    })
                                }
                            />
                            <InputGroup.Text>Per {selectedProduct.unitOfMeasure}</InputGroup.Text>
                        </InputGroup>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    updateModalShow();
                                    setSelectedProduct({
                                        id: "",
                                        name: "",
                                        weight: "",
                                        boxNumber: "",
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                {addProductModal ? "Add Product" : "Update"}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal start from here fetch product by id */}
            <Modal
                show={baseProductModal}
                onHide={() => {
                    setBaseProductModal(false);
                    setBaseProductItem(null);
                    setBaseProduct(null);
                }}
                dialogClassName="modal-90w"

            >
                <Modal.Header closeButton>
                    {baseProduct && (<Modal.Title>Assembly Products in {baseProductItem?.id}: {baseProductItem?.name}</Modal.Title>)}
                    {!baseProduct && (<Modal.Title>UMOs in {baseProductItem?.id}: {baseProductItem?.name}</Modal.Title>)}
                </Modal.Header>
                <Modal.Body>
                    {baseProduct && (<Table bordered>
                        <thead>
                            <tr>
                                <th>Product Id</th>
                                <th>Assembly Id</th>
                                <th>
                                    Name{" "}
                                    <span
                                        onClick={() => setAllSubExpanded(!allSubExpanded)}
                                        className="readButton"
                                    >
                                        <u>{allSubExpanded ? "Read Less" : "Read More"}</u>
                                    </span>
                                </th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Unit</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {baseProduct &&
                                baseProduct.map((dataItem, index) => (
                                    <tr key={index}>
                                        <td className="text-primary">
                                            <Link to={`/products/${dataItem.productId}`}
                                                className="text-primary"
                                                onClick={() => {
                                                    setBaseProductModal(false);
                                                }}
                                            >
                                                {dataItem.productId}
                                            </Link>
                                        </td>
                                        <td>{dataItem.APId}</td>
                                        <td>
                                            <TruncateCell
                                                text={dataItem.name}
                                                maxLength={allSubExpanded ? dataItem.name.length : 20}
                                            />
                                        </td>
                                        <td>{dataItem.price ? dataItem.price + "INR" : "-"}</td>
                                        <td>{dataItem.quantity}</td>
                                        <td>{dataItem.unitOfMeasure}</td>
                                        <td>
                                            {moment(dataItem.createdAt).format("DD/MM/YYYY HH:mm")}
                                        </td>
                                        <td>
                                            {moment(dataItem.updatedAt).format("DD/MM/YYYY HH:mm")}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>)}
                    {!baseProduct && <Table bordered>
                        <thead>
                            <tr>
                                <th>Unit Of Measure</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {baseProductItem &&
                                Object.keys(baseProductItem["UOMs"]).map((dataItem) => (
                                    <tr key={dataItem}>
                                        <td>{dataItem}</td>
                                        <td>{baseProductItem["UOMs"][dataItem]}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setBaseProductModal(false);
                            setBaseProductItem(null);
                            setBaseProduct(null);
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Product price list Modal*/}
            <Modal show={priceListModal} onHide={handlePriceListModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Product Price List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3 " controlId="price-list-download">
                        <Form.Label>Price List Download</Form.Label>
                        <Form.Select
                            className="w-50"
                            required
                            onChange={(e) => setSelectedUser(e.target.value)}
                        >
                            <option value="">Select Client</option>
                            {
                                clientList?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlePriceListModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handlePriceListDownload} disabled={priceDownloadLoading}>
                        Download List
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={clientFilterModal} onHide={handleClientFilter}>
                <Modal.Header closeButton>
                    <Modal.Title>Client Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3 ">
                        <Form.Label>Client</Form.Label>
                        <Form.Select
                            aria-label="Client list"
                            onChange={(e) => {
                                const selectedClientId = e.target.value;
                                const selectedClient = clientList.find(item => item.id === selectedClientId);
                                setClientFilterInput({
                                    ...clientFilterInput,
                                    id: selectedClientId,
                                    clientName: selectedClient.name
                                });
                            }}

                            value={clientFilterInput.id}
                        >
                            <option value="">Select Client</option>
                            {
                                clientList?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Select
                            aria-label="Shipment Code List"
                            onChange={(e) => {
                                const selectedOption = codeList.find(item => item.id === e.target.value);
                                if (selectedOption) {
                                    setClientFilterInput({
                                        ...clientFilterInput,
                                        slabID: selectedOption.id,
                                        shipmentName: `${selectedOption.country.name}, ${selectedOption.shipmentType.name}`
                                    });
                                }
                            }}
                            value={clientFilterInput.slabID}
                        >
                            <option value="">Select Shipment Type</option>
                            {
                                codeList.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.country.name}, {item.shipmentType.name}
                                    </option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Button onClick={clientFilterTableHandle}>Add</Button>
                    {
                        !!clientFilter.length && (
                            <Table bordered hover className="mt-4">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>slabID</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        clientFilter.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.clientName}</td>
                                                <td>{item.shipmentName}</td>
                                                <td>
                                                    <Button onClick={() => handleFilterTable(index)} variant="outline-danger" size="sm">
                                                        <i className="fa-solid fa-x" ></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>)
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClientFilter}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {
                        searchProductBtn()
                        handleClientFilter()
                    }} disabled={!clientFilter.length}>
                        Search
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* price breakdown modal */}
            <Modal show={priceBreakdownModal} onHide={() => {
                setPriceBreakdownValue([])
                setPriceBreakdownModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Price Breakdown</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    priceBreakdownValue.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item[0]}</td>
                                            <td>{item[1]}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        setPriceBreakdownValue([])
                        setPriceBreakdownModal(false)
                    }}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={tableReorderModal}
                onHide={() => {
                    setTableReorderModal(false);
                    setTableReorderState(productTableObj);
                }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change table order and visibility setting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="list">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {tableReorderState.map((item, index) => (
                                        <Draggable
                                            key={index}
                                            draggableId={`item-${index}`}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="d-flex justify-content-between align-items-center cursor-move"
                                                >
                                                    <div className="item-key-width fw-bold light_class">
                                                        {item.key}
                                                    </div>
                                                    <div>
                                                        <Form.Select
                                                            aria-label="Select Position"
                                                            onChange={(e) =>
                                                                handlePositionChange(
                                                                    index,
                                                                    Number(e.target.value)
                                                                )
                                                            }
                                                            value={tableReorderState.indexOf(item)}
                                                        >
                                                            {tableReorderState.map((item, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={tableReorderState.indexOf(item)}
                                                                >
                                                                    {tableReorderState.indexOf(item) + 1}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                    <div className="me-2">
                                                        <i
                                                            className={`light_class ${!item.disabled
                                                                ? "fa-solid fa-eye"
                                                                : "fa-solid fa-eye-slash"
                                                                }`}
                                                            role="button"
                                                            onClick={() => handleDisabledButton(item)}
                                                        ></i>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setTableReorderModal(false);
                            setTableReorderState(productTableObj);
                        }}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleTableSetting}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={errorModal}
                onHide={() => {
                    setErrorModal(false)
                }}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-90w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Validation Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorModalData?.map((data, i) => (
                        <li key={i}>{data}</li>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setErrorModal(false)
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
};

export default AdminProducts;
