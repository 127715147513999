import axios from "axios";

export const getEmail = (token) => {
  return axios.get(
    process.env.REACT_APP_API_URL + "/api/v1/admin/get-email-metadata",
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const updateMetaData = (token, data) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "/api/v1/admin/update-email-metadata",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const getAllProducts = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/get-all";
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const searchProduct = (token, filterArr, reqObj, orderBy, clientList) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/search";
  let obj = { filterArr };
  if (reqObj.offSet) {
    obj.offSet = reqObj.offSet;
  }
  if (reqObj.limit) {
    obj.limit = reqObj.limit;
  }
  if (reqObj.currencyID) {
    obj.currencyID = reqObj.currencyID
  }
  if (orderBy) {
    obj.orderBy = orderBy
  }
  if (clientList) {
    obj.clientList = clientList
  }
  return axios.post(url, obj, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getConfigByKey = (token, key) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/admin/get-config/";
  let params = { key };
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
};

export const updateConfigByKey = (token, key, value, metaData) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/admin/update-config/";
  return axios.patch(
    url,
    {
      key,
      value,
      metaData,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const getAllClients = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/user/get-all-clients";
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const uploadProducts = (formData, token) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/product/update-product",
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const updateProduct = (token, params) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/update";
  return axios.patch(url, params, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const batchProductUpdate = (token, formData) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/batch-update";
  return axios.post(url, formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addProduct = (token, params) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/add-product";
  return axios.post(url, params, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const mailDispatcher = (token) => {
  let url =
    process.env.REACT_APP_API_URL + "/api/v1/cron/dispatch-tracking-trigger";
  return axios.post(url, null, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const dailyAccTrigger = (token) => {
  let url =
    process.env.REACT_APP_API_URL + "/api/v1/cron/ledger-summary-daily-trigger";
  return axios.post(url, null, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateDispatchFromMSBC = (token) => {
  let url =
    process.env.REACT_APP_API_URL + "/api/v1/cron/update-tracking-details";
  return axios.post(url, null, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const fetchBasedProductById = (token, id) => {
  let url =
    process.env.REACT_APP_API_URL + "/api/v1/product/fetch-Base-Product-by-id";
  let params = { id };
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
};

export const getCurrencyCode = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/currency/get-list";
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateCurrency = (token, data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/currency/update";
  return axios.patch(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getWallet = (token, offSet) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/wallet/get-all/";
  let params = {};
  if (offSet) {
    params.offSet = offSet;
  }
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params
  });
};

export const addCurrency = (token, data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/currency/add";
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addAmount = (token, user) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/wallet/add-balance";
  return axios.post(url, user, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateCreditLimit = (token, data) => {
  let url =
    process.env.REACT_APP_API_URL + "/api/v1/wallet/update-credit-limit";
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getAllLedgerEntries = (token, limit, offSet, filterArr, orderBy) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/wallet/ledger";
  let obj = { filterArr };
  if (offSet) {
    obj.offSet = offSet;
  }
  if (limit) {
    obj.limit = limit;
  }
  if (orderBy) {
    obj.orderBy = orderBy;
  }
  return axios.post(url, obj, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const triggerMSBCProductsCron = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/cron/fetch-msbc-data-trigger";
  return axios.post(url, null, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export const getCountries = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/country/get-all";
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export const addCountry = (token, data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/country/add";
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateCountry = (token, data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/country/update";
  return axios.patch(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getUniqueValues = (token, params) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/admin/get-unique-values";
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
};

export const aggregateLedger = (token, params) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/wallet/ledger-aggregate";
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
};

export const priceListDownload = (token, clientId) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/price-list";
  let params = {}
  if (clientId) {
    params.clientId = clientId;
  }
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params
  });
};

export const historyData = (token, reqObj, orderBy) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/revisionHistory";
  let obj = {...reqObj};
  if (orderBy) {
    obj.orderBy = orderBy
  }

  return axios.post(url, obj, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export const getPriceSlabs = (token) =>{
  let url = process.env.REACT_APP_API_URL + "/api/v1/admin/get-shipmentid";
  return axios.get(url,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const fetchPrice = (token,data) =>{
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/fetchPrice"
  return axios.post(url,data,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const getNotificationData = (token)=>{
  let url = process.env.REACT_APP_API_URL + "/api/v1/admin/notification-config"
  return axios.get(url,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const getPriceGroup = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/price-group"
  return axios.get(url,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const updateNotificationData = (token,data)=>{
  let url = process.env.REACT_APP_API_URL + "/api/v1/admin/notification-config"
  return axios.patch(url,data,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const updatePriceGroup = (token,data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/price-group"
  return axios.patch(url,data,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const addPriceGroup = (token,data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/price-group"
  return axios.post(url,data,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const previewFile = (token,data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/preview-price-entities"
  return axios.post(url,data,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const updatePriceEntities = (token,data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/price-entities"
  return axios.post(url,{productJson:data},{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const downloadEntities = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/download-price-entities"
  return axios.get(url,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const entitiesData = (token, filterArr, limit, offSet, orderBy) => {
  let params = {filterArr};
  if (filterArr) {
    params.filterArr = filterArr;
  }
  if (limit) {
    params.limit = limit;
  }
  if (offSet) {
    params.offSet = offSet;
  }
  if (orderBy) {
    params.orderBy = orderBy;
  }
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/price-entities-list";
  return axios.post(url,params, {
    headers: { Authorization: `Bearer ${token}` },
    // params: params,
  });
};

export const upsertPriceEntities = (token,data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/single-price-entities"
  return axios.post(url,data,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const deletePriceEntity = (token, id) => {
  return axios.delete(
    process.env.REACT_APP_API_URL + "/api/v1/product/price-entity",
    {
      headers: { Authorization: `Bearer ${token}` },
      params: { id }
    }
  )
};

export const aggregateLedgerRef = (token, params) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/wallet/ledger-aggregate-ref";
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
};

export const rateConversion = (amount)=>{
  return axios.get(
    process.env.REACT_APP_API_URL + "/api/v1/wallet/coinMill-fetch",
    {
      params:{amount:amount}
    }
  )
}


export const inVoiceTemplate = (token,data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/label/generateInvoice"
  return axios.post(url,data,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const generalTemplate = (token,data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/label/generateTemplate"
  return axios.post(url,data,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const getProductList = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/sku-list"
  return axios.get(url,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const addProductSku = (token,data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/add-sku"
  return axios.post(url,data,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const skuList = (token, filterArr, reqObj, orderBy, clientList) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/search-sku"
  let obj = { filterArr };
  if (reqObj.offSet) {
    obj.offSet = reqObj.offSet;
  }
  if (reqObj.limit) {
    obj.limit = reqObj.limit;
  }
  if (orderBy) {
    obj.orderBy = orderBy
  }
  if (clientList) {
    obj.clientList = clientList
  }
  return axios.post(url, obj, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export const boxNoList = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/product/box-no-list"
  return axios.get(url,{
    headers:{
      Authorization:`Bearer ${token}`
    },
  })
}

export const deleteSku = (token, id) => {
  return axios.delete(
    process.env.REACT_APP_API_URL + "/api/v1/product/delete-sku",
    {
      headers: { Authorization: `Bearer ${token}` },
      params: { id }
    }
  )
};
