import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "./Services/userService";
import { setLogout } from "./State/store";
import "./App.css";
import UserList from "./Screens/userList/userList";
import Login from "./Screens/login/login";
import Dispatch from "./Screens/dispatch/dispatcher";
import Support from "./Screens/support/support";
import Navigation from "./Screens/navigation/navigation";
import Order from "./Screens/placeOrder/placeOrder";
import Products from "./Screens/products/products";
import OrderHistory from "./Screens/orderHistory/orderHistory";
import AdminConfig from "./Screens/adminConfig/adminConfig";
import OrderItemHistory from "./Screens/orderItemHistory/orderItemHistory";
import Tracking from "./Screens/tracking/tracking";
import Account from "./Screens/account/account";
import Currency from "./Screens/account/currency/currency";
import Wallet from "./Screens/account/wallet/wallet";
import Ledger from "./Screens/account/ledger/ledger";
import Shipment from "./Screens/account/shipment/shipment";
// import WalletDetails from "./Screens/wallet/wallet";
import Email from "./Screens/adminConfig/email/email";
import Country from "./Screens/adminConfig/country/country";
import ShipmentPriceSlab from "./Screens/account/shipmentPriceSlab/shipmentPriceSlab";
import AnonymousOrder from "./Screens/anonymousOrder/anonymousOrder";
import History from "./Screens/account/history/history";
import Notification from "./Screens/adminConfig/notification/notification";
import AdminProducts from "./Screens/adminProducts/adminProducts";
import AdminOrderItemHistory from "./Screens/adminOrderItemHistory/adminOrderItemHistory";
import PriceGroup from "./Screens/account/priceGroup/priceGroup";
import PriceEntities from "./Screens/account/priceEntities/priceEntities";
import Label from "./Screens/labelTemplate/labelTemplate";
import WalletDetails from "./Screens/wallet/wallet";
import ProductNavigation from "./Screens/adminProducts/productNavigation";
import ProductSku from "./Screens/adminProducts/product-sku/productSku";

function App() {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const theme = useSelector((state) => state.mode);
  const dispatch = useDispatch();

  useEffect(() => {
    isTokenValid();
    // eslint-disable-next-line
  }, []);

  let isTokenValid = async () => {
    try {
      if (token) {
        await verifyToken(token);
      }
    } catch (err) {
      dispatch(setLogout());
    }
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <div className={"App"}>
      <BrowserRouter>
        {user && <Navigation />}
        <Routes>
          {!user ? (
            <>
              <Route path="/" element={<Login />} />
            </>
          ) : null}
          {user && user.role === "client" ? (
            <>
              <Route path="/" element={<Order />} />
              <Route path="/support" element={<Support />} />
              <Route path="/orderHistory" element={<OrderHistory />} />
              <Route path="/orderItemHistory" element={<OrderItemHistory />} />
              <Route path="/wallet-details" element={<WalletDetails />} />
              <Route path="/orderItemHistory/:id" element={<OrderItemHistory />} />
            </>
          ) : null}
          {user && user.role === "admin" ? (
            <>
              <Route path="/" element={<UserList />} />
              <Route path="/placeOrder" element={<Order />} />
              <Route path="/products" element={<ProductNavigation />} >
                <Route path="/products" element={<AdminProducts />} />
                <Route path="/products/sku" element={<ProductSku />} />
              </Route>
              <Route path="/anonymous-order" element={<AnonymousOrder />} />
              <Route path="/orderHistory" element={<OrderHistory />} />
              <Route path="/config" element={<AdminConfig />}>
                <Route path="/config/email" element={<Email />} />
                <Route path="/config/country" element={<Country />} />
                <Route path="/config/notification" element={<Notification />} />
              </Route>
              <Route path="/tracking" element={<Tracking />} />
              <Route path="/orderItemHistory" element={<AdminOrderItemHistory />} />
              <Route path="/accounts" element={<Account />}>
                <Route path="/accounts/currency" element={<Currency />} />
                <Route path="/accounts/wallet" element={<Wallet />} />
                <Route path="/accounts/price-group" element={<PriceGroup />} />
                <Route path="/accounts/ledger" element={<Ledger />} />
                <Route path="/accounts/shipment" element={<Shipment />} />
                <Route path="/accounts/price-entities" element={<PriceEntities />} />
                <Route
                  path="/accounts/shipmentPriceSlab"
                  element={<ShipmentPriceSlab />}
                />
                <Route path="/accounts/history" element={<History />} />
              </Route>
              <Route path="/dispatch" element={<Dispatch />} />
              <Route path="/products/:id" element={<AdminProducts />} />
              <Route path="/orderItemHistory/:id" element={<AdminOrderItemHistory />} />
              <Route path="/label" element={<Label />} />
            </>
          ) : null}

          {user && user.role === "accountant" ? (
            <>
              <Route path="/" element={<UserList />} />
              <Route path="/placeOrder" element={<Order />} />
              <Route path="/anonymous-order" element={<AnonymousOrder />} />
              <Route path="/products" element={<AdminProducts />} />
              <Route path="/orderHistory" element={<OrderHistory />} />
              <Route path="/config" element={<AdminConfig />}>
                <Route path="/config/email" element={<Email />} />
                <Route path="/config/country" element={<Country />} />
                <Route path="/config/notification" element={<Notification />} />
              </Route>
              <Route path="/orderItemHistory" element={<AdminOrderItemHistory />} />
              <Route path="/accounts" element={<Account />}>
                <Route path="/accounts/currency" element={<Currency />} />
                <Route path="/accounts/wallet" element={<Wallet />} />
                <Route path="/accounts/price-group" element={<PriceGroup />} />
                <Route path="/accounts/ledger" element={<Ledger />} />
                <Route path="/accounts/shipment" element={<Shipment />} />
                <Route path="/accounts/price-entities" element={<PriceEntities />} />
                <Route
                  path="/accounts/shipmentPriceSlab"
                  element={<ShipmentPriceSlab />}
                />
                <Route path="/accounts/history" element={<History />} />
              </Route>
              <Route path="/products/:id" element={<AdminProducts />} />
              <Route path="/orderItemHistory/:id" element={<AdminOrderItemHistory />} />

            </>
          ) : null}

          {user && user.role === "dispatch" ? (
            <>
              <Route path="/" element={<Dispatch />} />
              <Route path="/label" element={<Label />} />
            </>
          ) : null}
          {user && user.role === "dispatch_manager" ? (
            <>
              <Route path="/" element={<Dispatch />} />
              <Route path="/placeOrder" element={<Order />} />
              <Route path="/anonymous-order" element={<AnonymousOrder />} />
              <Route path="/products" element={<Products />} />
              <Route path="/orderHistory" element={<OrderHistory />} />
              <Route path="/orderItemHistory" element={<OrderItemHistory />} />
              <Route path="/products/:id" element={<Products />} />
              <Route path="/orderItemHistory/:id" element={<OrderItemHistory />} />
              <Route path="/label" element={<Label />} />
            </>
          ) : null}
          {user && user.role === "tracker" ? (
            <>
              <Route path="/" element={<Tracking />} />
            </>
          ) : null}
          <Route path="/orderHistory/:id" element={<OrderHistory />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
